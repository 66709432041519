import { defineMessage } from "react-intl";

export const groups = defineMessage({
  id: "groups_unit",
  description: "Unit for groups",
  defaultMessage: "{count, plural, one {group} other {groups}}",
});

export const users = defineMessage({
  id: "users_unit",
  description: "Unit for users",
  defaultMessage: "{count, plural, one {user} other {users}}",
});

export const items = defineMessage({
  id: "items_unit",
  description: "Unit for items",
  defaultMessage: "{count, plural, one {item} other {items}}",
});

export const certifications = defineMessage({
  id: "certifications_unit",
  description: "Unit for certifications",
  defaultMessage: "{count, plural, one {certification} other {certifications}}",
});
