import React from "react";
import { DateTime } from "luxon";

interface IDurationProps {
  date: Date | undefined;
}

export const Duration: React.FC<IDurationProps> = ({ date }) => {
  /* const [updatedOn, setUpdatedOn] = useState('');

        useEffect(() => {
            let timer = setInterval(() => {
              // Force a redraw of this component every second
              setUpdatedOn(new Date().toString());
            }, 60000);
        
            return () => clearInterval(timer);
          }, []);
          */
  if (!date) {
    return <span>-</span>;
  }

  const dateTime = DateTime.fromJSDate(date);

  return (
    <time dateTime={date.toISOString()}>
      {dateTime.until(DateTime.local()).count("seconds") < 10
        ? "now"
        : dateTime.toRelative({ padding: 500 })}
    </time>
  );
};
