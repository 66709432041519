export const endpoint: string =
  process.env.REACT_APP_DASHBOARD_SERVICE_ENDPOINT || "http://localhost:5005";

function getParameterByName(name: string, url = window.location.href) {
  name = name.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export const getToken = () => {
  return getParameterByName("token");
};

export const hideHeader = () => {
  return getParameterByName("hideheader") === "true";
};

export const bgcolor = () => {
  return getParameterByName("bgcolor");
};

export const placeholderValues = () => {
  const encodedPlaceholders = getParameterByName("placeholders");

  if (!encodedPlaceholders || !encodedPlaceholders.length) {
    return {};
  }

  return JSON.parse(encodedPlaceholders);
};

export const nuApiEndpoint: string =
  process.env.REACT_APP_DASHBOARD_NU_API_ENDPOINT ||
  "https://apigateway.cmbackbone.net";
