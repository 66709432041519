import { makeAutoObservable } from "mobx";
import { ITileSpecificOptionModel } from "../../Dialogs/TilePropertiesModel";
import { ITileModel } from "../ITileModel";

export const CONTENT_TYPE_KEY = 'CONTENT_TYPE';

export interface SupportedType {
    label: string;
    key: string;
    activityTypes: string[];
}

export const supportedTypes = [
    {
        label: 'All',
        key: '*',
        activityTypes: []
    },
    {
        label: 'Courses',
        key: 'courses',
        activityTypes: [ 
            'http://adlnet.gov/expapi/activities/course'
        ]
    },
    {
        label: 'Files',
        key: 'files',
        activityTypes: [
            'http://activitystrea.ms/schema/1.0/file'
        ]
    },
    {
        label: 'Events',
        key: 'events',
        activityTypes: [
            'http://activitystrea.ms/schema/1.0/event'
        ]
    },
    {
        label: 'Links',
        key: 'links',
        activityTypes: [
            'http://adlnet.gov/expapi/activities/link'
        ]
    },
    {
        label: 'Curricula',
        key: 'curricula',
        activityTypes: [
            'http://activitystrea.ms/schema/1.0/collection'
        ]
    },
    {
        label: 'Tasks',
        key: 'tasks',
        activityTypes: [
            'http://activitystrea.ms/schema/1.0/task'
        ]
    }
];



export class ContentTypeOptionModel implements ITileSpecificOptionModel {
    selectedContentType: SupportedType = supportedTypes[0];

    constructor(
        public tile: ITileModel
    ) {
        makeAutoObservable(this);

        this.setSelectedContentType(tile.item.item.options[CONTENT_TYPE_KEY]);
    }

    public save() {
        this.tile.item.item.options[CONTENT_TYPE_KEY] = this.selectedContentType.key;
    }

    public setSelectedContentType(key: string) {
        this.selectedContentType = this.supportedTypes.find(
            t => t.key === key
        ) || this.supportedTypes[0];
    }

    public get supportedTypes(): SupportedType[] {
        return supportedTypes;
    }
}
