import { observer } from "mobx-react";
import React from "react";
import { Button } from "react-bootstrap";
import { MdAdd } from "react-icons/md";
import { useIntl } from "react-intl";
import { ItemType } from "../../Models/DashboardItem";
import { DashboardState } from "../../Models/DashboardState";
import { AddTileDialog } from "../Dialogs/AddTileDialog";

export interface IAddTileControlProps {
  dashboardState: DashboardState;
}

export const AddTileControl: React.FC<IAddTileControlProps> = observer(
  ({ dashboardState }) => {
    const intl = useIntl();

    if (dashboardState.isReadonly) return <></>;

    return (
      <>
        <Button
          style={{ float: "right" }}
          variant="link"
          className="filter-text"
          disabled={dashboardState.isReadonly}
          title={intl.formatMessage({
            id: "addItem",
            defaultMessage: "Add a new tile to this dashboard",
          })}
          onClick={(e) => {
            e.preventDefault();
            dashboardState.showAddItemDialog();
          }}
        >
          <MdAdd />
        </Button>
        <AddTileDialog
          show={dashboardState.addItemDialogVisible}
          hide={(type) => dashboardState.hideAddItemDialog(type as ItemType)}
        />
      </>
    );
  }
);
