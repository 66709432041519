import { DateTime, Duration } from "luxon";
import { makeAutoObservable } from "mobx";
import { MessageDescriptor } from "react-intl";
import { users } from "../../Controls/Units";

import { StatisticRequest } from "../../Interfaces/stats.interfaces";
import { formatDuration } from "../../Utilities/DurationFormatter";
import { DashboardItem, ItemDimensions, prepareItem } from "../DashboardItem";
import { DashboardState } from "../DashboardState";
import { IDialogModel } from "../Dialogs/IDialogModel";
import { TilePropertiesModel } from "../Dialogs/TilePropertiesModel";
import { ICountTileWithTrendTileModel } from "./ICountWithTrendTileModel";
import { DurationOptionModel } from "./Options/DurationOptionModel";
import {
  TileTrendOptionModel,
  TrendDisplayMode,
  trendFromString,
  TREND_OPTION_KEY,
} from "./Options/TileTrendOptionModel";

const REGISTERED_PERIOD_KEY = "REGISTERED_PERIOD";

const defaultOptions = {
  [REGISTERED_PERIOD_KEY]: "P1M",
  [TREND_OPTION_KEY]: "None",
};

export class RegisteredUserCountTileModel
  implements ICountTileWithTrendTileModel {
  public propertiesDialogModel: TilePropertiesModel | null = null;
  public dialog: IDialogModel | null = null;

  constructor(
    public readonly dashboard: DashboardState,
    public readonly item: DashboardItem
  ) {
    prepareItem(item, "registered_user_count", defaultOptions);

    makeAutoObservable(this);
  }

  public onClickRemove() {
    this.dashboard.removeTile(this);
  }

  public onClickInfo() {
    this.propertiesDialogModel = new TilePropertiesModel(this, [
      new TileTrendOptionModel(this),
      new DurationOptionModel(
        this,
        REGISTERED_PERIOD_KEY,
        "Registered in last",
        "P1M"
      ),
    ]);
  }

  public buildRequests(): StatisticRequest[] {
    const requests = [
      {
        type: "user_registered",
        options: {
          from: DateTime.utc().minus(this.duration).startOf("day").toString(),
          to: DateTime.utc().endOf("day").toString(),
        },
      },
    ];

    if (this.trendMode !== TrendDisplayMode.None) {
      requests.push({
        type: "user_registered",
        options: {
          from: DateTime.utc()
            .minus(this.duration)
            .minus(this.duration)
            .startOf("day")
            .toString(),
          to: DateTime.utc().minus(this.duration).startOf("day").toString(),
        },
      });
    }

    return requests as StatisticRequest[];
  }

  get dimensions(): ItemDimensions {
    return {
      w: 1,
      h: 1,
    };
  }

  get canResize(): boolean {
    return false;
  }

  get defaultLabels() {
    return {
      title: "New registrations",
      subTitle: `Past ${formatDuration(this.duration)}`,
    };
  }

  get value(): number {
    const cs = this.dashboard.getStatistic(this.buildRequests()[0]);

    if (!cs || !cs.response) {
      return NaN;
    }

    if (cs?.response?.type !== "value") {
      throw new Error(`Unexpected response type`);
    }

    return cs?.response.value;
  }

  get historicValue(): number {
    const cs = this.dashboard.getStatistic(this.buildRequests()[1]);

    if (!cs || !cs.response) {
      return NaN;
    }

    if (cs?.response?.type !== "value") {
      throw new Error(`Unexpected response type`);
    }

    return cs?.response.value;
  }

  get duration() {
    return Duration.fromISO(
      (this.item.item.options &&
        this.item.item.options[REGISTERED_PERIOD_KEY]) ||
        "P1M"
    );
  }

  public onClickTile() {}

  get isClickable(): boolean {
    return false;
  }

  get isLoading(): boolean {
    const cs = this.dashboard.getStatistic(this.buildRequests()[0]);

    return !cs || !cs.response;
  }

  get isReadonly(): boolean {
    return this.dashboard.isReadonly;
  }

  get trendMode(): TrendDisplayMode {
    return trendFromString(this.item.item.options[TREND_OPTION_KEY]);
  }

  get hasItem(): boolean {
    return true;
  }

  get emptyText(): string {
    return "";
  }

  get unit(): MessageDescriptor {
    return users;
  }
}
