import { makeAutoObservable } from "mobx";
import { ITileSpecificOptionModel } from "../../Dialogs/TilePropertiesModel";
import { ITileModel } from "../ITileModel";

export const TREND_OPTION_KEY = 'TREND';

export enum TrendDisplayMode {
    None, // Do not show any trend data
    Direction, // Just show the direction
    Proportional, // Show trends as a percentage
    Absolute // Show trend as a count
}

export const trendFromString = (value: string | undefined): TrendDisplayMode => {
    switch (value) {
        case 'Direction':
            return TrendDisplayMode.Direction;
        case 'Proportional':
            return TrendDisplayMode.Proportional;
        case 'Absolute':
            return TrendDisplayMode.Absolute;
        default:
        case undefined:
        case 'None':
            return TrendDisplayMode.None;
    }
}

export const stringFromTrend = (value: TrendDisplayMode): string => {
    switch (value) {
        case TrendDisplayMode.None:
            return 'None';

        case TrendDisplayMode.Direction:
            return 'Direction';

        case TrendDisplayMode.Proportional:
            return 'Proportional';

        case TrendDisplayMode.Absolute:
            return 'Absolute';
    }
}

export class TileTrendOptionModel implements ITileSpecificOptionModel {
    public selectedTrendDisplayMode: TrendDisplayMode = TrendDisplayMode.None;

    constructor(
        public tile: ITileModel
    ) {
        makeAutoObservable(this);

        this.selectedTrendDisplayMode = 
            trendFromString(tile.item.item.options[TREND_OPTION_KEY]); 
    }

    public setSelectedTrend(mode: TrendDisplayMode) {
        this.selectedTrendDisplayMode = mode;
    }

    public save() {
        this.tile.item.item.options[TREND_OPTION_KEY] = stringFromTrend(this.selectedTrendDisplayMode);
    }
}
