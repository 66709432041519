import React from 'react';
import { observer } from "mobx-react";
import { Button, Form, Modal, Col } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
import { NewDashboardPropertiesModel } from '../../Models/Dialogs/NewDashboardPropertiesModel';

export interface INewDashboardPropertiesDialogProps {
    model: NewDashboardPropertiesModel | null;
}

export const NewDashboardPropertiesDialog: React.FC<INewDashboardPropertiesDialogProps> =
    observer((props: INewDashboardPropertiesDialogProps) => {
        if (!props.model) return <></>;

        return (
            <Modal
                show={props.model !== null}
                hide={() => props.model?.cancel()}>
                <Modal.Header>
                    <Modal.Title>
                    <FormattedMessage
                            id="addADashboard"
                            defaultMessage="Add a dashboard"
                            />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form onSubmit={() => props.model?.save() }>
                        <Form.Row>
                            <Form.Group as={Col} controlId="dashboard.title">
                                <Form.Label>
                                    <FormattedMessage
                                        id="dashboardTitle"
                                        defaultMessage="Title"
                                    />
                                </Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Title"
                                    value={props.model?.title}
                                    onChange={(e) => props.model?.setTitle(e.target.value)} />
                            </Form.Group>
                        </Form.Row>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button 
                        variant="primary" 
                        disabled={!props.model.isValid}
                        onClick={() => props.model?.save()}>
                        <FormattedMessage
                            id="save"
                            defaultMessage="Save"
                            />
                    </Button>

                    <Button variant="secondary" onClick={() => props.model?.cancel()}>
                        <FormattedMessage
                            id="cancel"
                            defaultMessage="Cancel"
                            />
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    });
