import React from "react";

import { ITileSpecificOptionModel } from "../../../Models/Dialogs/TilePropertiesModel";
import { BooleanOptionModel } from "../../../Models/Tiles/Options/BooleanOptionModel";
import { CertificationOptionModel } from "../../../Models/Tiles/Options/CertificationOptionModel";
import { ContentItemCategoryOptionModel } from "../../../Models/Tiles/Options/ContentItemCategoryOptionModel";
import { ContentItemOptionModel } from "../../../Models/Tiles/Options/ContentItemOptionModel";
import { ContentTypeOptionModel } from "../../../Models/Tiles/Options/ContentTypeOptionModel";
import { DurationOptionModel } from "../../../Models/Tiles/Options/DurationOptionModel";
import { ProportionGraphOptionModel } from "../../../Models/Tiles/Options/ProportionGraphOptionModel";
import { TileTrendOptionModel } from "../../../Models/Tiles/Options/TileTrendOptionModel";
import { BooleanOption } from "./BooleanOption";
import { CertificationOption } from "./CertificationOption";
import { ContentItemCategoryOption } from "./ContentItemCategoryOption";
import { ContentItemOption } from "./ContentItemOption";
import { ContentTypeOption } from "./ContentTypeOption";
import { DurationOption } from "./DurationOption";
import { ProportionGraphTypeOption } from "./ProportionGraphTypeOption";
import { TrendOptions } from "./TrendOptions";

interface ITileOptionProps {
  option: ITileSpecificOptionModel;
}

export const TileOption: React.FC<ITileOptionProps> = ({
  option,
}: ITileOptionProps) => {
  if (option instanceof TileTrendOptionModel) {
    return <TrendOptions model={option} />;
  } else if (option instanceof DurationOptionModel) {
    return <DurationOption model={option} />;
  } else if (option instanceof ContentTypeOptionModel) {
    return <ContentTypeOption model={option} />;
  } else if (option instanceof ProportionGraphOptionModel) {
    return <ProportionGraphTypeOption model={option} />;
  } else if (option instanceof ContentItemOptionModel) {
    return <ContentItemOption model={option} />;
  } else if (option instanceof ContentItemCategoryOptionModel) {
    return <ContentItemCategoryOption model={option} />;
  } else if (option instanceof CertificationOptionModel) {
    return <CertificationOption model={option} />;
  } else if (option instanceof BooleanOptionModel) {
    return <BooleanOption model={option} />;
  } else {
    return <>Unsupported tile option</>;
  }
};
