import { axisLeft, format } from "d3";
import { ScaleLinear } from "d3-scale";

export const yAxis = (
  leftMargin: number,
  width: number,
  scale: ScaleLinear<number, number, never>,
  units: string,
  includeHorizontalLines: boolean
): ((g: d3.Selection<SVGGElement, unknown, null, undefined>) => void) => {
  return (g) => {
    const integerYTicks = scale.ticks().filter((n) => Number.isInteger(n));

    const element = g
      .attr("transform", `translate(${leftMargin},0)`)
      .call(axisLeft(scale).tickValues(integerYTicks).tickFormat(format("d")))
      .call((g) => g.select(".domain").remove())
      .call((g) =>
        g
          .append("text")
          .attr("x", -leftMargin)
          .attr("y", 8)
          .attr("fill", "currentColor")
          .attr("text-anchor", "start")
          .attr("font-weight", "bold")
          .text(units)
      );

    if (includeHorizontalLines) {
      element.call((g) =>
        g
          .selectAll(".tick line")
          .clone()
          .attr("x2", width)
          .attr("stroke-opacity", 0.1)
      );
    }
  };
};
