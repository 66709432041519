import { makeAutoObservable } from "mobx";
import { Layout } from "react-grid-layout";
import { StatContext, StatisticRequest } from "../Interfaces/stats.interfaces";
import { hideHeader } from "./Config";
import { DashboardItem, ItemType } from "./DashboardItem";
import { PlaceholderType, UserDashboard } from "./DashboardsStore";
import { DashboardFilterDialogModel } from "./Dialogs/DashboardFilterDialogModel";
import { DashboardPropertiesModel } from "./Dialogs/DashboardPropertiesModel";
import { DialogStore } from "./DialogStore";
import { StatsStore } from "./StatsStore";
import { ActivatedUserCountTileModel } from "./Tiles/ActivatedUserCountTileModel";
import { ActiveUserCountTileModel } from "./Tiles/ActiveUserCountTileModel";
import { CertificationCertifiedUserCountModel } from "./Tiles/Certifications/CertificationCertifiedUsersModel";
import { CertificationEnrolledUserCountModel } from "./Tiles/Certifications/CertificationEnrolledUserCount";
import { CertificationExpiredUserCount } from "./Tiles/Certifications/CertificationExpiredUserCount";
import { CertificationsCountTileModel } from "./Tiles/Certifications/CertificationsCountTileModel";
import { CertificationUserStatesModel } from "./Tiles/Certifications/CertificationUserStatesModel";
import { ContentItemCompletedHistogramModel } from "./Tiles/Completion/ContentItemCompletedHistogramModel";
import { ContentItemAttemptedModel } from "./Tiles/ContentItemAttemptedModel";
import { ContentItemCompletionHistogramTileModel } from "./Tiles/ContentItemCompletionHistogram";
import { ContentItemCompletionsModel } from "./Tiles/ContentItemCompletionsModel";
import { ContentItemCountTileModel } from "./Tiles/ContentItemCountTileModel";
import { ContentItemEnrolmentHistogramTileModel } from "./Tiles/ContentItemEnrolmentHistogramTileModel";
import { ContentItemEnrolmentModel } from "./Tiles/ContentItemEnrolmentModel";
import { ContentItemTypesTileModel } from "./Tiles/ContentItemTypesTileModel";
import { ContentItemUserStatusesModel } from "./Tiles/ContentItemUserStatusesModel";
import { ITileModel } from "./Tiles/ITileModel";
import { RegisteredUserCountTileModel } from "./Tiles/RegisteredUserCountTileModel";
import { UserCountTileModel } from "./Tiles/UserCountTileModel";
import { UserLoginHistogramTileModel } from "./Tiles/UserLoginHistogramTileModel";

export type FilterMode = "None" | "Group";

export class DashboardState {
  id: string;

  title: string = "";

  tileModels: ITileModel[] = [];

  items: DashboardItem[] = [];

  filterMode: FilterMode = "None";

  filterByGroupId: string | null = null;

  addItemDialogVisible: boolean = false;

  propertiesDialogVisible: boolean = false;

  propertiesDialogModel: DashboardPropertiesModel | null = null;

  filterDialogModel: DashboardFilterDialogModel | null = null;

  constructor(
    public readonly statsStore: StatsStore,
    public readonly dialogStore: DialogStore,
    private readonly dashboard: UserDashboard,
    private readonly placeholderValues: {
      [type in PlaceholderType]: string | undefined;
    }
  ) {
    makeAutoObservable(this);

    for (const item of dashboard.definition.items) {
      if (item.x === undefined && item.gridLayout) {
        item.x = item.gridLayout.x;
      }

      if (item.y === undefined && item.gridLayout) {
        item.y = item.gridLayout.y;
      }

      if (!item.item.options) {
        item.item.options = {};
      }
    }

    this.id = dashboard.id;
    this.title = dashboard.title;
    this.items = dashboard.definition.items;

    this.tileModels = dashboard.definition.items.map((i) =>
      this.tileModelFromData(i)
    );
  }

  public async fetchStats(): Promise<void> {
    const requests: StatisticRequest[] = this.tileModels
      .map((m) => m.buildRequests())
      .flat();

    const filterContext = this.contextFromFilters();

    if (filterContext) {
      requests.forEach((r) => (r.context = filterContext));
    }

    if (requests.length) {
      await this.statsStore.fetchStatistics(requests);
    }
  }

  public updateFilterMode(filterMode: FilterMode) {
    this.filterMode = filterMode;
  }

  public updateFilterGroup(groupId: string | null) {
    this.filterByGroupId = groupId;
  }

  public getStatistic(request: StatisticRequest) {
    const statWithContext = Object.assign({}, request, {
      context: this.contextFromFilters(),
    });

    return this.statsStore.getStatistic(statWithContext);
  }

  public removeTile(tile: ITileModel) {
    this.tileModels = this.tileModels.filter((t) => t !== tile);
  }

  public showAddItemDialog() {
    this.addItemDialogVisible = true;
  }

  public hideAddItemDialog(newTileType: ItemType | null) {
    this.addItemDialogVisible = false;

    if (newTileType) {
      console.log(`Add new ${newTileType} tile`);

      const dashboardItem: DashboardItem = {
        id: `N${new Date().getTime()}`,
        x: 0,
        y:
          this.items.length === 0
            ? 0
            : Math.max(...this.items.map((i) => i.y)) + 1,
        item: {
          type: newTileType,
          options: {},
        },
        title: "",
        subTitle: "",
      };

      this.tileModels.push(this.tileModelFromData(dashboardItem));

      this.fetchStats();
    }
  }

  public updateLayouts(layouts: Layout[]) {
    for (const layout of layouts) {
      const tileModel = this.tileModels.find((m) => m.item.id === layout.i);

      if (!tileModel) {
        console.warn(`Layout received for unknown tile ${layout.i}`);
        continue;
      }

      tileModel.item.x = layout.x;
      tileModel.item.y = layout.y;
      tileModel.item.w = tileModel.dimensions.w = layout.w;
      tileModel.item.h = tileModel.dimensions.h = layout.h;
    }
  }

  public showPropertiesDialog() {
    this.propertiesDialogVisible = true;
  }

  public hidePropertiesDialog() {
    this.propertiesDialogVisible = false;
  }

  public showFilterDialog() {
    this.filterDialogModel = new DashboardFilterDialogModel(this);
  }

  public serialize() {
    return {
      id: this.id,
      title: this.title,
      definition: {
        items: this.tileModels.map((t) => ({
          id: t.item.id,
          item: t.item.item,
          x: t.item.x,
          y: t.item.y,
          h: t.item.h,
          w: t.item.w,
          title: t.item.title,
          subTitle: t.item.subTitle,
        })),
      },
    };
  }

  get hideHeader(): boolean {
    return hideHeader();
  }

  get isReadonly(): boolean {
    return (
      (this.placeholderValues !== null &&
        Object.keys(this.placeholderValues).length > 0) ||
      this.dashboard.isReadonly
    );
  }

  public contextFromFilters(): StatContext | undefined {
    if (this.filterMode === "None") {
      return undefined;
    }

    if (this.filterMode === "Group" && this.filterByGroupId !== null) {
      return {
        groupFilters: [this.filterByGroupId],
        propertyFilters: [],
      };
    }

    return undefined;
  }

  public getParameterValue(type: PlaceholderType) {
    const value = this.placeholderValues[type];

    if (!value) {
      return null;
    }

    return value;
  }

  private tileModelFromData(itemData: DashboardItem): ITileModel {
    switch (itemData.item.type) {
      case "user_count":
        return new UserCountTileModel(this, itemData);
      case "activated_user_count":
        return new ActivatedUserCountTileModel(this, itemData);
      case "active_user_count":
        return new ActiveUserCountTileModel(this, itemData);
      case "registered_user_count":
        return new RegisteredUserCountTileModel(this, itemData);
      case "login_histogram":
        return new UserLoginHistogramTileModel(this, itemData);
      case "content_item_count":
        return new ContentItemCountTileModel(this, itemData);
      case "content_item_types":
        return new ContentItemTypesTileModel(this, itemData);
      case "item_enrolment_count":
        return new ContentItemEnrolmentModel(this, itemData);
      case "item_attempted_count":
        return new ContentItemAttemptedModel(this, itemData);
      case "item_completion_count":
        return new ContentItemCompletionsModel(this, itemData);
      case "item_completion_histogram":
        return new ContentItemCompletedHistogramModel(this, itemData);
      case "item_user_statuses":
        return new ContentItemUserStatusesModel(this, itemData);
      case "completion_histogram":
        return new ContentItemCompletionHistogramTileModel(this, itemData);
      case "enrolment_histogram":
        return new ContentItemEnrolmentHistogramTileModel(this, itemData);
      case "certification_count":
        return new CertificationsCountTileModel(this, itemData);
      case "certification_enrolment_count":
        return new CertificationEnrolledUserCountModel(this, itemData);
      case "certification_certified_count":
        return new CertificationCertifiedUserCountModel(this, itemData);
      case "certification_expired_count":
        return new CertificationExpiredUserCount(this, itemData);
      case "certification_user_states":
        return new CertificationUserStatesModel(this, itemData);
    }
  }
}
