import { makeAutoObservable } from "mobx";

export interface ToastMessage {
  id: string;
  title: string;
  createdAt: Date;
}

let toastId = 0;

export class ToastStore {
  toasts: ToastMessage[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public addToast(title: string) {
    this.toasts.push({
      id: `TOAST_${toastId++}`,
      title,
      createdAt: new Date(),
    });
  }

  public removeToast(message: ToastMessage) {
    const i = this.toasts.findIndex((t) => t === message);

    if (i !== -1) {
      this.toasts.splice(i, 1);
    }
  }
}
