import { observer } from "mobx-react";
import React from "react";
import { Form } from "react-bootstrap";
// import { FormattedMessage } from "react-intl";
import { SingleCategoryPicker } from "@agylia/nu-web-ui";
import { ContentItemCategoryOptionModel } from "../../../Models/Tiles/Options/ContentItemCategoryOptionModel";

export interface IContentItemOptionProps {
  model: ContentItemCategoryOptionModel;
}

export const ContentItemCategoryOption: React.FC<IContentItemOptionProps> = observer(
  ({ model }: IContentItemOptionProps) => {
    // TODO: Figure out how to nicely localise the label here

    return (
      <Form.Group controlId={model.key}>
        <Form.Label>{model.label}</Form.Label>
        <SingleCategoryPicker
          isReadonly={model.isReadonly}
          setSelectedItemId={(categoryId) => model.set(categoryId)}
          selectedItemId={model.selectedCategoryId}
        />
      </Form.Group>
    );
  }
);
