import { observer } from 'mobx-react';
import React from 'react';
import { Button } from 'react-bootstrap';
import { MdFilterList } from 'react-icons/md';
import { useIntl } from 'react-intl';
import { DashboardState } from '../../Models/DashboardState';
import { DashboardFilterDialog } from '../Dialogs/DashboardFilterDialog';

import './FilterDashboardControl.css';

export interface IFilterDashboardControlProps {
    dashboardState: DashboardState
}

export const FilterDashboardControl: React.FC<IFilterDashboardControlProps> = observer(
    ({ dashboardState }) => {
    const intl = useIntl();

    return (
        <>
            <Button variant="link" className="filter-text" onClick={(e) => {e.preventDefault(); dashboardState.showFilterDialog()}} >
                <MdFilterList 
                    style={{ fontSize: '1.2em', verticalAlign: 'text-top' }} 
                    title={intl.formatMessage({
                        id: 'filterThisDashboard',
                        defaultMessage: 'Filter this dashboard'
                    })} />
            </Button>
            {
                dashboardState.filterDialogModel &&
                <DashboardFilterDialog
                    model={dashboardState.filterDialogModel}
                />
            }
           
        </>
    )
});