import { makeAutoObservable } from "mobx";
import { ITileSpecificOptionModel } from "../../Dialogs/TilePropertiesModel";
import { ITileModel } from "../ITileModel";

export class ContentItemCategoryOptionModel
  implements ITileSpecificOptionModel {
  public selectedCategoryId: string | null = null;

  constructor(
    public tile: ITileModel,
    public key: string,
    public label: string
  ) {
    makeAutoObservable(this);

    this.selectedCategoryId = tile.item.item.options[key] || null;
  }

  public set(activityId: string | null) {
    this.selectedCategoryId = activityId;
  }

  public save() {
    if (this.selectedCategoryId) {
      this.tile.item.item.options[this.key] = this.selectedCategoryId;
    } else {
      delete this.tile.item.item.options[this.key];
    }
  }

  public get isReadonly() {
    return this.tile.isReadonly;
  }
}
