import { makeAutoObservable } from "mobx";
import { ITileModel } from "../Tiles/ITileModel";

export interface ITileSpecificOptionModel {
  save(): void;
}
export type ConfigurationValidator = (
  options: ITileSpecificOptionModel[]
) => string | null;

export class TilePropertiesModel {
  public isReadonly: boolean = false;

  public title: string = "";

  public subTitle: string = "";

  constructor(
    private tile: ITileModel,
    public tileSpecificOptionModels: ITileSpecificOptionModel[],
    private validator?: ConfigurationValidator
  ) {
    makeAutoObservable(this);

    this.title = tile.item.title || "";
    this.subTitle = tile.item.subTitle || "";

    this.isReadonly = tile.isReadonly;
  }

  public setTitle(newTitle: string) {
    this.title = newTitle;
  }

  public setSubtitle(newSubtitle: string) {
    this.subTitle = newSubtitle;
  }

  public save() {
    if (this.validator) {
      const validationMessage = this.validator(this.tileSpecificOptionModels);

      if (validationMessage) {
        alert(validationMessage);
        return;
      }
    }

    this.tile.item.title = this.title;
    this.tile.item.subTitle = this.subTitle;

    for (const option of this.tileSpecificOptionModels) {
      option.save();
    }

    this.tile.dashboard.fetchStats();

    this.close();
  }

  public close() {
    this.tile.propertiesDialogModel = null;
  }

  public get defaultTitle() {
    return this.tile.defaultLabels.title;
  }

  public get defaultSubTitle() {
    return this.tile.defaultLabels.subTitle;
  }
}
