import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Button, Form, ListGroup, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useStores } from '../../Hooks/use-stores';

export interface IUserGroupPickerDialogProps {
    show: boolean;
    hide: (selectedGroupId: string | null) => void;
    selectedGroupId: string | null;
}

export const UserGroupPickerDialog: React.FC<IUserGroupPickerDialogProps> = 
    observer((props: IUserGroupPickerDialogProps) => {
        const { usersDataStore } = useStores();
        const [ searchText, setSearchText ] = useState('');

        const filteredGroups = 
            usersDataStore.groups.filter((g) => searchText === '' || g.name.toLowerCase().includes(searchText.toLowerCase()));

    return (
        <Modal 
            show={props.show}
            onEnter={() => usersDataStore.fetchGroups()}
            onHide={props.hide}>
            <Modal.Header closeButton>
                <Modal.Title>
                <FormattedMessage
                    id="selectAGroup"
                    defaultMessage="Select a group"
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Control 
                    type='text'
                    placeholder='Search...'
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)} />
                {
                    usersDataStore.loadingGroups && 'Loading...'
                }
                {
                    !usersDataStore.loadingGroups && 
                    <ListGroup variant='flush' style={{ paddingTop: 5 }}>
                        {
                            
                            filteredGroups.map((g) => 
                                <ListGroup.Item action key={g.id} onClick={() => props.hide(g.id)}>
                                    {g.name}
                                </ListGroup.Item>
                            )
                        }
                        {
                            filteredGroups.length === 0 &&
                            <ListGroup.Item>
                                No groups found
                            </ListGroup.Item>
                        }
                        
                    </ListGroup>
                }
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={() => props.hide(null)}>
                <FormattedMessage
                    id="close"
                    defaultMessage="Close"
                    />
            </Button>
            </Modal.Footer>
        </Modal>
    );
})
