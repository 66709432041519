import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Duration } from 'luxon';

export interface ITimeDurationPickerProps {
    disabled: boolean;
    value: string;
    onChange: (newValue: string) => void;
}

export const TimeDurationPicker: React.FC<ITimeDurationPickerProps> = 
    (props: ITimeDurationPickerProps) => {
        const duration = Duration.fromISO(props.value);

        if (!duration.isValid) {
            console.error(`Unable to parse duration ${props.value}: ${duration.invalidReason}`);
        }

        let value = 0;
        let unit = "D";

        if (duration.years > 0) {
            value = duration.years;
            unit = 'Y';
        } else if (duration.months > 0) {
            value = duration.months;
            unit = 'M';
        } else if (duration.weeks > 0) {
            value = duration.weeks;
            unit = 'W';
        } else {
            value = duration.days;
        }

        return (
            <>
            <Row>
                <Col>
                    <Form.Control
                        type="number"
                        required
                        value={value}
                        disabled={props.disabled}
                        onChange={(e) => {
                            props.onChange(`P${e.target.value}${unit}`);
                        }}
                         />
                </Col>
                <Col>
                    <Form.Control
                        as="select"
                        value={unit}
                        disabled={props.disabled}
                        onChange={(e) => {
                            props.onChange(`P${value}${e.target.value}`);
                        }}>
                        <option value="D">Days</option>
                        <option value="M">Months</option>
                        <option value="W">Weeks</option>
                        <option value="Y">Years</option>
                    </Form.Control>
                </Col>
            </Row>
            </>
        );
    };
