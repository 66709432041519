import { Duration } from "luxon"

// TODO: Need to figure out a nicer way to localise and handle this
export const formatDuration = (duration: Duration): string => {
    const parts: string[] = [];

    if (duration.years) {
        parts.push(`${duration.years} ${duration.years === 1 ? 'year' : 'years'}`);
    }

    if (duration.months) {
        parts.push(`${duration.months} ${duration.months === 1 ? 'month' : 'months'}`);
    }

    if (duration.weeks) {
        parts.push(`${duration.weeks} ${duration.weeks === 1 ? 'week' : 'weeks'}`);
    }

    if (duration.days) {
        parts.push(`${duration.days} ${duration.days === 1 ? 'day' : 'days'}`);
    }

    return parts.join(', ');
};
