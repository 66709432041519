import { AuthStore } from "@agylia/nu-web-auth";
import { makeAutoObservable, runInAction } from "mobx";
import { endpoint } from "./Config";

export interface UserGroup {
  id: string;
  name: string;
}

export class UsersDataStore {
  groups: UserGroup[] = [];
  loadingGroups: boolean = false;

  constructor(private readonly authStore: AuthStore) {
    makeAutoObservable(this);
  }

  public async fetchGroups() {
    const route = `${endpoint}/users/groups`;

    if (this.loadingGroups) return;

    runInAction(() => {
      this.loadingGroups = true;
    });

    const token = await this.authStore.getAccessToken();

    const req = fetch(route, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    const response = await req;

    if (!response.ok) {
      throw new Error(`Error loading user groups`);
    }

    const json = await response.json();

    runInAction(() => {
      this.groups = json.groups;

      this.loadingGroups = false;
    });
  }

  public getGroupName(groupId: string): string | null {
    const group = this.groups.find((g) => g.id === groupId);

    if (!group) {
      return null;
    }

    return group.name;
  }
}
