import classNames from "classnames";
import React from "react";
import { TrendDisplayMode } from "../../Models/Tiles/Options/TileTrendOptionModel";

import styles from "./CountControl.module.css";
import { TrendDisplay } from "./TrendDisplay";

export interface ICountControlProps {
  count: number;
  historicCount?: number;
  title: string;
  subTitle?: string;
  trendDisplayMode: TrendDisplayMode;
  unit?: string;
  loading: boolean;
  icon?: string;
  message?: string;
}

export const CountControl: React.FC<ICountControlProps> = ({
  count,
  title,
  subTitle,
  unit,
  historicCount,
  trendDisplayMode,
  loading,
  message,
}: ICountControlProps) => {
  const countStyles = classNames(styles.count, {
    loading: !message && loading,
    [styles.message]: !!message,
  });

  return (
    <>
      <h2 className={styles.title}>{title}</h2>
      <div className="value">
        <span className={countStyles}>
          {isNaN(count) ? message || "-" : count.toLocaleString()}
        </span>
        {!message && unit && !loading && (
          <span className={styles.unit}>{unit}</span>
        )}
      </div>
      {subTitle && <h3 className="subtitle">{subTitle}</h3>}
      {historicCount !== undefined && (
        <TrendDisplay
          currentValue={count}
          historicValue={historicCount}
          trendDisplayMode={trendDisplayMode}
        />
      )}
    </>
  );
};

CountControl.displayName = "CountControl";
