import { makeAutoObservable } from "mobx";
import { ITileSpecificOptionModel } from "../../Dialogs/TilePropertiesModel";
import { ITileModel } from "../ITileModel";

export const PROPORTION_GRAPH_TYPE_KEY = 'PROPORTION_GRAPH_TYPE';

export enum ProportionGraphType {
    Pie,
    Bar
}

export const typeFromString = (value: string | undefined): ProportionGraphType => {
    switch (value) {
        case 'Bar':
            return ProportionGraphType.Bar;
        default:
        case undefined:
        case 'Pie':
            return ProportionGraphType.Pie;
    }
}

export const stringFromType = (value: ProportionGraphType): string => {
    switch (value) {
        case ProportionGraphType.Bar:
            return 'Bar';
        case ProportionGraphType.Pie:
            return 'Pie';
    }
}

export class ProportionGraphOptionModel implements ITileSpecificOptionModel {
    public selectedType: ProportionGraphType = ProportionGraphType.Pie;

    constructor(
        public tile: ITileModel
    ) {
        makeAutoObservable(this);

        this.selectedType = 
            typeFromString(tile.item.item.options[PROPORTION_GRAPH_TYPE_KEY]); 
    }

    public setSelectedTrend(mode: ProportionGraphType) {
        this.selectedType = mode;
    }

    public save() {
        this.tile.item.item.options[PROPORTION_GRAPH_TYPE_KEY] = stringFromType(this.selectedType);
    }
}
