import { makeAutoObservable } from "mobx";
import { IDialogModel } from "./Dialogs/IDialogModel";

export class DialogStore {
  constructor() {
    makeAutoObservable(this);
  }

  public currentDialog: IDialogModel | null = null;

  public showDialog(dialog: IDialogModel) {
    if (this.currentDialog) {
      throw new Error(`Unable to show dialog when already showing dialog`);
    }

    this.currentDialog = dialog;
  }

  public hideDialog() {
    this.currentDialog = null;
  }
}
