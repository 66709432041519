import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Grid } from "./Grid";
import { DashboardState } from "./Models/DashboardState";
import { useStores } from "./Hooks/use-stores";

import { MdAutorenew, MdInfoOutline } from "react-icons/md";
import { FilterDashboardControl } from "./Controls/Dashboard/FilterDashboardControl";
import { useIntl } from "react-intl";
import { DashboardPropertiesDialog } from "./Controls/Dialogs/DashboardPropertiesDialog";
import { DashboardSelector } from "./Controls/Dashboard/DashboardSelector";
import { Duration } from "./Controls/Utility/Duration";
import { FilterStatus } from "./Controls/Dashboard/FilterStatus";
import { AddTileControl } from "./Controls/Dashboard/AddTileControl";
import { SaveControl } from "./Controls/Dashboard/SaveControl";
import { useInterval } from "./Hooks/use-interval";

export interface IDashboardProps {
  state: DashboardState;
}

const DashboardHeader: React.FC<IDashboardProps> = observer(
  ({ state }: IDashboardProps) => {
    const intl = useIntl();

    return (
      <header>
        <DashboardSelector currentDashboard={state} />

        <FilterDashboardControl dashboardState={state} />
        <FilterStatus dashboardState={state} />

        <AddTileControl dashboardState={state} />
        <SaveControl dashboardState={state} />

        <h3 style={{ float: "right" }}>
          {false && (
            <MdInfoOutline
              style={{ cursor: "pointer" }}
              title={intl.formatMessage({
                id: "properties",
                defaultMessage: "View the properties for this dashboard",
              })}
              onClick={() => state.showPropertiesDialog()}
            />
          )}
        </h3>
        {state.propertiesDialogModel && (
          <DashboardPropertiesDialog
            model={state.propertiesDialogModel}
            show={state.propertiesDialogVisible}
            hide={() => state.hidePropertiesDialog()}
          />
        )}
      </header>
    );
  }
);

export const Dashboard = observer(({ state }: IDashboardProps) => {
  const { statsStore } = useStores();

  useInterval(() => {
    state.fetchStats();
  }, 30000);

  return (
    <div className="nu-dashboard-app">
      <Helmet>
        <title>{state.title}</title>
      </Helmet>
      {!state.hideHeader && <DashboardHeader state={state} />}
      <Grid dashboard={state} />
      <footer>
        <p>
          <MdAutorenew
            style={{ cursor: "pointer" }}
            onClick={() => {
              state.fetchStats();
            }}
            title="Refresh"
          />{" "}
          Updated <Duration date={statsStore.updatedOn} />
        </p>
      </footer>
    </div>
  );
});

export interface IDashboardPageParams {
  id: string;
}

export const DashboardById = () => {
  const { dashboardsStore } = useStores();
  const params = useParams<IDashboardPageParams>();
  const history = useHistory();

  const dashboardId = params.id;

  const dashboard = dashboardsStore.dashboards.find(
    (d) => d.id === dashboardId
  );

  useEffect(() => {
    dashboard?.fetchStats();
  });

  if (!dashboard) {
    history.replace("/");

    return <div>Unknown dashboard.</div>;
  }

  if (dashboardsStore.dashboardToLoad === dashboard) {
    dashboardsStore.dashboardToLoad = null;
  }

  return <Dashboard state={dashboard} />;
};
