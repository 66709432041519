import { observer } from "mobx-react";

import { computeTileDimensions } from "../../../Grid";
import { TilePropertiesDialog } from "../../Dialogs/TilePropertiesDialog";
import { TileButtons } from "../TileButtons";
import { TimeHistogramControl } from "../Graphs/TimeHistogramControl";
import { IModelProps } from "../../helpers";
import { IHistogramTileModel } from "../../../Models/Tiles/IHistogramTileModel";
import { useIntl } from "react-intl";

export const HistogramTile: React.FC<IModelProps<
  IHistogramTileModel
>> = observer(({ model }: { model: IHistogramTileModel }) => {
  const intl = useIntl();
  const tileDimensions = computeTileDimensions(model.dimensions);
  const svgHeight = tileDimensions.height - 55;
  const margin = {
    top: 20,
    right: 35,
    bottom: model.dimensions.h > 1 ? 20 : 0,
    left: 25,
  };
  const subTitle = model.item.subTitle || model.defaultLabels.subTitle;

  return (
    <>
      <TileButtons model={model} />

      <h2 className="histogram-title">
        {model.item.title || model.defaultLabels.title}
      </h2>

      {model.value && (
        <TimeHistogramControl
          entries={model.value.entries}
          margin={margin}
          width={tileDimensions.width}
          height={svgHeight}
          units={intl.formatMessage(model.unit, { count: 0 })}
        />
      )}
      {subTitle && <h3 className="histogram-subtitle">{subTitle}</h3>}
      {model.propertiesDialogModel && (
        <TilePropertiesDialog model={model.propertiesDialogModel} />
      )}
    </>
  );
});
