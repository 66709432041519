import React from "react";
import { observer } from "mobx-react";
import ReactGridLayout, { Layout } from "react-grid-layout";

import "./Grid.css";
import { DashboardState } from "./Models/DashboardState";
import { buildTile } from "./Controls/Tiles/buildTile";
import { ItemDimensions } from "./Models/DashboardItem";
import classNames from "classnames";

export const GRID_ITEM_SIZE = 200;
export const GRID_ITEM_GAP = 20;
export const GRID_ITEM_TITLE_PADDING = 80;

type GridProps = {
  dashboard: DashboardState;
};

type GridState = {
  currentBreakpoint: string;
  mounted: boolean;
};

export function computeTileDimensions(dimensions: ItemDimensions) {
  return {
    width: dimensions.w * GRID_ITEM_SIZE + (dimensions.w - 1) * GRID_ITEM_GAP,
    height: dimensions.h * GRID_ITEM_SIZE + (dimensions.h - 1) * GRID_ITEM_GAP,
  };
}

@observer
class Grid extends React.Component<GridProps, GridState> {
  constructor(props: GridProps) {
    super(props);

    this.state = {
      currentBreakpoint: "lg",
      mounted: false,
    };
  }

  onBreakpointChange = (breakpoint: string) => {
    this.setState({
      currentBreakpoint: breakpoint,
    });
  };

  componentDidMount() {
    this.setState({ mounted: true });
  }

  onLayoutChange = (layouts: Layout[]) => {
    this.props.dashboard.updateLayouts(layouts);
  };

  render() {
    const { tileModels } = this.props.dashboard;

    return (
      <ReactGridLayout
        className="grid"
        width={900}
        draggableHandle=".grid-item-handle"
        isDraggable={!this.props.dashboard.isReadonly}
        layout={tileModels.map((i) => ({
          ...i.dimensions,
          i: i.item.id,
          x: i.item.x || 0,
          y: i.item.y || 0,
          resizeHandles: !i.isReadonly && i.canResize ? ["se"] : [],
          // This prevents weirdness interacting with the properties dialog, which is part of the tile
          static: i.propertiesDialogModel !== null,
        }))}
        cols={4}
        rowHeight={GRID_ITEM_SIZE}
        containerPadding={[0, 0]}
        margin={[GRID_ITEM_GAP, GRID_ITEM_GAP]}
        compactType="vertical"
        onLayoutChange={this.onLayoutChange}
      >
        {tileModels.map((l) => {
          const fullClassName = classNames("grid-item", {
            "grid-item-small": l.dimensions.h === 1,
            "grid-item-clickable": l.isClickable,
          });

          return (
            <div key={l.item.id} className={fullClassName}>
              {buildTile(l)}
            </div>
          );
        })}
      </ReactGridLayout>
    );
  }
}

export { Grid };
