import React from 'react';
import { observer } from "mobx-react";
import { Button, Form, Modal } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
import { DashboardPropertiesModel } from '../../Models/Dialogs/DashboardPropertiesModel';

export interface IDashboardPropertiesDialogProps {
    show: boolean;
    hide: () => void;

    model: DashboardPropertiesModel;
}

export const DashboardPropertiesDialog: React.FC<IDashboardPropertiesDialogProps> =
    observer((props: IDashboardPropertiesDialogProps) => {
        return (
            <Modal
                show={props.show}
                hide={props.hide}>
                <Modal.Header>
                    <Modal.Title>
                    <FormattedMessage
                            id="dashboardProperties"
                            defaultMessage="Dashboard properties"
                            />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group controlId="dashboard.title">
                            <Form.Label>
                                <FormattedMessage
                                    id="dashboardTitle"
                                    defaultMessage="Title"
                                />
                                <Form.Control 
                                    type="text" 
                                    placeholder="Title"
                                    value={props.model.title}
                                    onChange={(e) => props.model.setTitle(e.target.value)} />
                            </Form.Label>
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={() => props.hide()}>
                        <FormattedMessage
                            id="save"
                            defaultMessage="Save"
                            />
                    </Button>

                    <Button variant="secondary" onClick={() => props.hide()}>
                        <FormattedMessage
                            id="cancel"
                            defaultMessage="Cancel"
                            />
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    });
