import { observer } from "mobx-react";
import React from "react";
import { Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { stringFromTrend, TileTrendOptionModel, TrendDisplayMode, trendFromString } from "../../../Models/Tiles/Options/TileTrendOptionModel";

interface ITrendOptionsProps {
    model: TileTrendOptionModel
}

export const TrendOptions: React.FC<ITrendOptionsProps> =
    observer(({ model }: ITrendOptionsProps) => {
        return (
            <Form.Group controlId="tile.trend">
                <Form.Label>
                    <FormattedMessage
                        id="tileTrendDisplay"
                        defaultMessage="Display a trend for this data on the tile"
                    />
                </Form.Label>
                <Form.Control 
                    as="select" 
                    disabled={model.tile.isReadonly}
                    value={stringFromTrend(model.selectedTrendDisplayMode)}
                    onChange={(e) => model.setSelectedTrend(trendFromString(e.target.value))}>
                        <option value={stringFromTrend(TrendDisplayMode.None)}>None</option>
                        <option value={stringFromTrend(TrendDisplayMode.Direction)}>Direction</option>
                        <option value={stringFromTrend(TrendDisplayMode.Proportional)}>Percentage</option>
                        <option value={stringFromTrend(TrendDisplayMode.Absolute)}>Change</option>
                </Form.Control>
            </Form.Group>
        )
    });