import React from "react";
import { observer } from "mobx-react";
import { Button, Form, Modal, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { TilePropertiesModel } from "../../Models/Dialogs/TilePropertiesModel";
import { TileOption } from "./TileOptions/TileOption";

export interface ITilePropertiesDialogProps {
  model: TilePropertiesModel;
}

export const TilePropertiesDialog: React.FC<ITilePropertiesDialogProps> = observer(
  ({ model }: ITilePropertiesDialogProps) => {
    return (
      <Modal
        show={true}
        onHide={() => model.close()}
        backdrop="static"
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage
              id="tileProperties"
              defaultMessage="Tile properties"
            />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Row>
              <Form.Group as={Col} controlId="tile.title">
                <Form.Label>
                  <FormattedMessage id="tileTitle" defaultMessage="Title" />
                </Form.Label>
                <Form.Control
                  type="text"
                  disabled={model.isReadonly}
                  placeholder={model.defaultTitle}
                  value={model.title}
                  onChange={(e) => model.setTitle(e.target.value)}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="tile.subtitle">
                <Form.Label>
                  <FormattedMessage
                    id="tileSubtitle"
                    defaultMessage="Subtitle"
                  />
                </Form.Label>
                <Form.Control
                  type="text"
                  disabled={model.isReadonly}
                  placeholder={model.defaultSubTitle}
                  value={model.subTitle}
                  onChange={(e) => model.setSubtitle(e.target.value)}
                />
              </Form.Group>
            </Form.Row>

            {model.tileSpecificOptionModels.map((o) => (
              <TileOption option={o} />
            ))}
          </Form>
        </Modal.Body>

        <Modal.Footer>
          {!model.isReadonly && (
            <Button
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                model.save();
              }}
            >
              <FormattedMessage id="save" defaultMessage="Save" />
            </Button>
          )}
          <Button
            variant="secondary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              model.close();
            }}
          >
            <FormattedMessage id="close" defaultMessage="Cancel" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
);

/* <Form.Group controlId="activeUsers.duration">
                            <Form.Label>
                                <FormattedMessage
                                    id="activeUsersDuration"
                                    defaultMessage="Active in last"
                                />
                            </Form.Label>
                            <TimeDurationPicker 
                                value={duration}
                                onChange={setDuration} />
                        </Form.Group>
                        */
