import React from "react";
import { CertificationCertifiedUserCountModel } from "../../Models/Tiles/Certifications/CertificationCertifiedUsersModel";
import { CertificationEnrolledUserCountModel } from "../../Models/Tiles/Certifications/CertificationEnrolledUserCount";
import { CertificationsCountTileModel } from "../../Models/Tiles/Certifications/CertificationsCountTileModel";
import { ContentItemTypesTileModel } from "../../Models/Tiles/ContentItemTypesTileModel";
import { ICountTileModel } from "../../Models/Tiles/ICountTileModel";
import { ICountTileWithTrendTileModel } from "../../Models/Tiles/ICountWithTrendTileModel";
import { IHistogramTileModel } from "../../Models/Tiles/IHistogramTileModel";
import { ITileModel } from "../../Models/Tiles/ITileModel";
import { CountTile } from "./CountTile";
import { CountTileWithTrend } from "./CountWithTrendTile";
import { HistogramTile } from "./Graphs/HistogramTile";
import { IProportionTileModel, ProportionTile } from "./Graphs/ProportionTile";
export function buildTile(model: ITileModel): JSX.Element {
  switch (model.item.item.type) {
    case "user_count":
      return (
        <CountTile
          key={model.item.id}
          model={(model as unknown) as ICountTileModel}
        />
      );
    case "activated_user_count":
      return (
        <CountTile
          key={model.item.id}
          model={(model as unknown) as ICountTileModel}
        />
      );
    case "active_user_count":
      return (
        <CountTileWithTrend
          key={model.item.id}
          model={(model as unknown) as ICountTileWithTrendTileModel}
        />
      );
    case "registered_user_count":
      return (
        <CountTileWithTrend
          key={model.item.id}
          model={(model as unknown) as ICountTileWithTrendTileModel}
        />
      );
    case "login_histogram":
      return (
        <HistogramTile
          key={model.item.id}
          model={(model as unknown) as IHistogramTileModel}
        />
      );
    case "content_item_count":
      return (
        <CountTile
          key={model.item.id}
          model={(model as unknown) as ICountTileModel}
        />
      );
    case "content_item_types":
      return (
        <ProportionTile
          key={model.item.id}
          model={(model as unknown) as ContentItemTypesTileModel}
        />
      );
    case "item_enrolment_count":
      return (
        <CountTile
          key={model.item.id}
          model={(model as unknown) as ICountTileModel}
        />
      );
    case "item_attempted_count":
      return (
        <CountTile
          key={model.item.id}
          model={(model as unknown) as ICountTileModel}
        />
      );
    case "item_completion_count":
      return (
        <CountTile
          key={model.item.id}
          model={(model as unknown) as ICountTileModel}
        />
      );
    case "item_completion_histogram":
      return (
        <HistogramTile
          key={model.item.id}
          model={(model as unknown) as IHistogramTileModel}
        />
      );
    case "item_user_statuses":
      return (
        <ProportionTile
          key={model.item.id}
          model={(model as unknown) as IProportionTileModel}
        />
      );
    case "completion_histogram":
      return (
        <HistogramTile
          key={model.item.id}
          model={(model as unknown) as IHistogramTileModel}
        />
      );
    case "enrolment_histogram":
      return (
        <HistogramTile
          key={model.item.id}
          model={(model as unknown) as IHistogramTileModel}
        />
      );
    case "certification_count":
      return (
        <CountTile
          key={model.item.id}
          model={(model as unknown) as CertificationsCountTileModel}
        />
      );
    case "certification_enrolment_count":
      return (
        <CountTile
          key={model.item.id}
          model={(model as unknown) as CertificationEnrolledUserCountModel}
        />
      );
    case "certification_certified_count":
      return (
        <CountTile
          key={model.item.id}
          model={(model as unknown) as CertificationCertifiedUserCountModel}
        />
      );
    case "certification_expired_count":
      return (
        <CountTile
          key={model.item.id}
          model={(model as unknown) as ICountTileModel}
        />
      );
    case "certification_user_states":
      return (
        <ProportionTile
          key={model.item.id}
          model={(model as unknown) as IProportionTileModel}
        />
      );
  }
}
