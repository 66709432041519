import { makeAutoObservable } from "mobx";
import { v4 as uuidv4 } from "uuid";

import { DashboardsStore } from "../DashboardsStore";

export class NewDashboardPropertiesModel {
  constructor(private dashboardsStore: DashboardsStore) {
    makeAutoObservable(this);
  }

  public title: string = "";

  public setTitle(newTitle: string) {
    this.title = newTitle;
  }

  public save() {
    this.dashboardsStore.addUserDashboard({
      id: uuidv4(),
      title: this.title,
      isReadonly: false,
      definition: {
        items: [],
        supportedPlaceholders: [],
      },
    });

    this.dashboardsStore.hideAddDashboardDialog();
  }

  public cancel() {
    this.dashboardsStore.hideAddDashboardDialog();
  }

  public get isValid(): boolean {
    return this.title.length > 0;
  }
}
