import { observer } from "mobx-react";
import React from "react";
import { Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { ProportionGraphOptionModel, ProportionGraphType, stringFromType, typeFromString } from "../../../Models/Tiles/Options/ProportionGraphOptionModel";

interface IProportionGraphTypeOptionProps {
    model: ProportionGraphOptionModel
}

export const ProportionGraphTypeOption: React.FC<IProportionGraphTypeOptionProps> =
    observer(({ model }: IProportionGraphTypeOptionProps) => {
        return (
            <Form.Group controlId="tile.proportionGraphType">
                <Form.Label>
                    <FormattedMessage
                        id="tileProportionGraphType"
                        defaultMessage="Graph type"
                    />
                </Form.Label>
                <Form.Control 
                    as="select" 
                    disabled={model.tile.isReadonly}
                    value={stringFromType(model.selectedType)}
                    onChange={(e) => model.setSelectedTrend(typeFromString(e.target.value))}>
                        <option value={stringFromType(ProportionGraphType.Pie)}>Pie</option>
                        <option value={stringFromType(ProportionGraphType.Bar)}>Bar</option>
                </Form.Control>
            </Form.Group>
        )
    });
