import React from "react";
import { MdTrendingDown, MdTrendingFlat, MdTrendingUp } from "react-icons/md";
import { TrendDisplayMode } from "../../Models/Tiles/Options/TileTrendOptionModel";
import styles from "./TrendDisplay.module.css";

export enum Trend {
  Unchanged,
  Up,
  Down,
}

const TrendDirection: React.FC<{ trend: Trend }> = ({
  trend,
}: {
  trend: Trend;
}) => {
  switch (trend) {
    case Trend.Up:
      return <MdTrendingUp className={styles.icon} />;
    case Trend.Down:
      return <MdTrendingDown className={styles.icon} />;
    case Trend.Unchanged:
    default:
      return <MdTrendingFlat />;
  }
};

export interface ITrendDisplayProps {
  trendDisplayMode: TrendDisplayMode;
  currentValue: number;
  historicValue: number;
}

export const TrendDisplay: React.FC<ITrendDisplayProps> = ({
  currentValue,
  historicValue,
  trendDisplayMode,
}: ITrendDisplayProps) => {
  if (trendDisplayMode === TrendDisplayMode.None) {
    return <></>;
  }

  const hasNaN = isNaN(currentValue) || isNaN(historicValue);

  const trend =
    currentValue > historicValue
      ? Trend.Up
      : currentValue < historicValue
      ? Trend.Down
      : Trend.Unchanged;

  if (trendDisplayMode === TrendDisplayMode.Direction) {
    return (
      <h3 className={styles.trend}>
        <TrendDirection trend={trend} />
      </h3>
    );
  }

  if (trendDisplayMode === TrendDisplayMode.Absolute) {
    return (
      <h3 className={styles.trend}>
        <TrendDirection trend={trend} />
        {hasNaN ? "-" : Math.abs(historicValue - currentValue)}
      </h3>
    );
  }

  if (trendDisplayMode === TrendDisplayMode.Proportional) {
    const diff = Math.abs(currentValue - historicValue);
    const prop = currentValue !== 0 ? diff / currentValue : -1;
    const percentage = prop * 100;

    return (
      <h3 className={styles.trend}>
        <TrendDirection trend={trend} />
        {hasNaN ? "-" : percentage.toFixed(0)}%
      </h3>
    );
  }

  return <></>;
};

TrendDisplay.displayName = "TrendDisplay";
