import { makeAutoObservable } from "mobx";
import { IProportionTileModel } from "../../Controls/Tiles/Graphs/ProportionTile";
import { StatisticRequest } from "../../Interfaces/stats.interfaces";
import { DashboardItem, ItemDimensions, prepareItem } from "../DashboardItem";
import { DashboardState } from "../DashboardState";
import { TilePropertiesModel } from "../Dialogs/TilePropertiesModel";
import {
  ProportionGraphOptionModel,
  ProportionGraphType,
  PROPORTION_GRAPH_TYPE_KEY,
  stringFromType,
  typeFromString,
} from "./Options/ProportionGraphOptionModel";

export type PieDataPoint = {
  number: number;
  title: string;
};

const defaultOptions = {
  [PROPORTION_GRAPH_TYPE_KEY]: stringFromType(ProportionGraphType.Pie),
};

export class ContentItemTypesTileModel implements IProportionTileModel {
  public propertiesDialogModel: TilePropertiesModel | null = null;

  constructor(
    public readonly dashboard: DashboardState,
    public readonly item: DashboardItem
  ) {
    prepareItem(item, "content_item_types", defaultOptions);

    makeAutoObservable(this);
  }

  public buildRequests(): StatisticRequest[] {
    return this.contentTypes.map((itemType) => ({
      type: "content_item_count",
      options: {
        TYPES: itemType.activityType,
      },
    }));
  }

  public onClickRemove() {
    this.dashboard.removeTile(this);
  }

  public onClickInfo() {
    this.propertiesDialogModel = new TilePropertiesModel(this, [
      //    new ContentTypeOptionModel(this)
      new ProportionGraphOptionModel(this),
    ]);
  }

  get emptyText() {
    return "No content items found";
  }

  get units() {
    return "Items";
  }

  get contentTypes() {
    return [
      {
        label: "Courses",
        activityType: "http://adlnet.gov/expapi/activities/course",
        color: "rgb(66, 136, 181)",
      },
      {
        label: "Links",
        activityType: "http://adlnet.gov/expapi/activities/link",
        color: "rgb(143, 210, 164)",
      },
      {
        label: "Files",
        activityType: "http://activitystrea.ms/schema/1.0/file",
        color: "rgb(232, 246, 164)",
      },
      {
        label: "Events",
        activityType: "http://activitystrea.ms/schema/1.0/event",
        color: "rgb(254, 229, 150)",
      },
      {
        label: "Curricula",
        activityType: "http://activitystrea.ms/schema/1.0/collection",
        color: "rgb(249, 148, 86)",
      },
      {
        label: "Tasks",
        activityType: "http://activitystrea.ms/schema/1.0/task",
        color: "rgb(209, 60, 75)",
      },
    ];
  }

  get defaultLabels() {
    return {
      title: "Content items",
      subTitle: "All items",
    };
  }

  get dimensions(): ItemDimensions {
    return {
      w: 2,
      h: 2,
    };
  }

  get canResize(): boolean {
    return false;
  }

  get values(): PieDataPoint[] | null {
    const responses = this.buildRequests().map((req) => {
      const result = this.dashboard.getStatistic(req);

      if (!result?.response) {
        return null;
      }

      if (result?.response?.type !== "value") {
        throw new Error(`Unexpected response type`);
      }

      return result?.response.value;
    });

    if (responses.some((v) => v === null)) return null;

    return responses
      .filter((r) => r !== null && r > 0)
      .map((value, i) => {
        const contentType = this.contentTypes[i];

        return {
          title: contentType.label,
          number: value || 0,
          color: contentType.color,
        };
      });
  }

  get isLoading(): boolean {
    return this.buildRequests().some(
      (s) => !this.dashboard.getStatistic(s)?.response
    );
  }

  get isReadonly(): boolean {
    return this.dashboard.isReadonly;
  }

  public onClickTile() {}

  get isClickable(): boolean {
    return false;
  }

  get graphType(): ProportionGraphType {
    const selectedType =
      this.item.item.options[PROPORTION_GRAPH_TYPE_KEY] ||
      stringFromType(ProportionGraphType.Pie);

    return typeFromString(selectedType);
  }
}
