import React from "react";
import { StatsStore } from "../Models/StatsStore";
import { DashboardsStore } from "../Models/DashboardsStore";
import { UsersDataStore } from "../Models/UsersDataStore";
import { ToastStore } from "../Models/ToastStore";
import { useAgyliaAuthContext } from "@agylia/nu-web-auth";
import { DialogStore } from "../Models/DialogStore";

export interface IDashboardContext {
  toastStore: ToastStore | null;
  dashboardsStore: DashboardsStore | null;
  statsStore: StatsStore | null;
  usersDataStore: UsersDataStore | null;
  dialogStore: DialogStore | null;
}

export const DashboardContext = React.createContext<IDashboardContext>({
  toastStore: null,
  dashboardsStore: null,
  statsStore: null,
  usersDataStore: null,
  dialogStore: null,
});

export const DashboardContextProvider: React.FC<{}> = ({ children }) => {
  const authContext = useAgyliaAuthContext();

  if (authContext.authStore === null) {
    throw new Error("Unable to start with null authstore");
  }

  const statsStore = new StatsStore(authContext.authStore);
  const toastStore = new ToastStore();
  const dialogStore = new DialogStore();

  const stores = {
    statsStore,
    toastStore,
    dashboardsStore: new DashboardsStore(
      authContext.authStore,
      statsStore,
      toastStore,
      dialogStore
    ),
    usersDataStore: new UsersDataStore(authContext.authStore),
    dialogStore: dialogStore,
  };

  return (
    <DashboardContext.Provider value={stores}>
      {children}
    </DashboardContext.Provider>
  );
};
