import { DateTime, Duration } from "luxon";
import { makeAutoObservable } from "mobx";
import { users } from "../../../Controls/Units";
import {
  StatisticRequest,
  TimeHistogram,
} from "../../../Interfaces/stats.interfaces";
import { formatDuration } from "../../../Utilities/DurationFormatter";
import {
  DashboardItem,
  ItemDimensions,
  prepareItem,
} from "../../DashboardItem";
import { DashboardState } from "../../DashboardState";
import {
  ITileSpecificOptionModel,
  TilePropertiesModel,
} from "../../Dialogs/TilePropertiesModel";
import { CATALOGUE_ENTRY_PLACEHOLDER } from "../../Placeholders";
import { IHistogramTileModel } from "../IHistogramTileModel";
import {
  ContentItemOptionModel,
  CONTENT_ITEM_KEY,
} from "../Options/ContentItemOptionModel";
import { DurationOptionModel } from "../Options/DurationOptionModel";

const TOTAL_DURATION = "TOTAL_DURATION";
const INTERVAL_DURATION = "INTERVAL_DURATION";

const MAX_INTERVAL_COUNT = 100;

const defaultOptions = {
  [CONTENT_ITEM_KEY]: "",
  [TOTAL_DURATION]: "P3M",
  [INTERVAL_DURATION]: "P1D",
};

export class ContentItemCompletedHistogramModel implements IHistogramTileModel {
  public propertiesDialogModel: TilePropertiesModel | null = null;
  public dimensions: ItemDimensions = {
    w: 3,
    h: 2,
  };

  constructor(
    public readonly dashboard: DashboardState,
    public readonly item: DashboardItem
  ) {
    prepareItem(item, "item_completion_histogram", defaultOptions);

    makeAutoObservable(this);
  }

  public buildRequests(): StatisticRequest[] {
    return [this.buildRequest()];
  }

  public onClickRemove() {
    this.dashboard.removeTile(this);
  }

  public onClickInfo() {
    this.propertiesDialogModel = new TilePropertiesModel(
      this,
      [
        new ContentItemOptionModel(this, CONTENT_ITEM_KEY, "Content item"),
        new DurationOptionModel(
          this,
          TOTAL_DURATION,
          "Total duration to view histogram over",
          "P3M"
        ),
        new DurationOptionModel(
          this,
          INTERVAL_DURATION,
          "Length of each sub-interval",
          "P1D"
        ),
      ],
      this.validateOptions
    );
  }

  private buildRequest(): StatisticRequest {
    return {
      type: "completion_item_histogram",
      options: {
        end: DateTime.utc().endOf("day").toString(),
        interval: this.interval.toString(),
        start: DateTime.utc().minus(this.duration).startOf("day").toString(),
        [CONTENT_ITEM_KEY]: this.contentItemId || "",
      },
    };
  }

  defaultLabels = {
    title: "Completions",
    subTitle: `Past ${formatDuration(this.duration)}`,
  };

  get value(): TimeHistogram | null {
    const cs = this.dashboard.getStatistic(this.buildRequest());

    if (!cs || !cs.response) {
      return null;
    }

    if (cs?.response?.type !== "histogram") {
      throw new Error(`Unexpected response type`);
    }

    return cs?.response;
  }

  public onClickTile() {}

  get isClickable(): boolean {
    return false;
  }

  get unit() {
    return users;
  }

  get emptyText() {
    return "No item selected";
  }

  get hasItem(): boolean {
    return this.contentItemId !== null;
  }

  get isLoading(): boolean {
    const cs = this.dashboard.getStatistic(this.buildRequest());

    return !cs || !cs.response;
  }

  get isReadonly(): boolean {
    return this.dashboard.isReadonly;
  }

  get canResize(): boolean {
    return true;
  }

  get duration() {
    return Duration.fromISO(this.item.item.options[TOTAL_DURATION] || "P3M");
  }

  get interval() {
    return Duration.fromISO(this.item.item.options[INTERVAL_DURATION] || "P1D");
  }

  private get contentItemId(): string | null {
    const placeholder = this.dashboard.getParameterValue(
      CATALOGUE_ENTRY_PLACEHOLDER
    );

    if (placeholder) return placeholder;

    return this.item.item.options[CONTENT_ITEM_KEY] || null;
  }

  validateOptions(options: ITileSpecificOptionModel[]): string | null {
    const duration = options[1] as DurationOptionModel;
    const interval = options[2] as DurationOptionModel;

    if (
      duration.duration.as("hours") / interval.duration.as("hours") >
      MAX_INTERVAL_COUNT
    ) {
      return "Too many intervals selected. Please choose a shorter reporting duration or a longer sub-interval";
    }

    return null;
  }
}
