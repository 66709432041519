import { IDialogModel } from "../../Models/Dialogs/IDialogModel";
import { CertificationCertifiedUsersDialogModel } from "../../Models/Tiles/Certifications/CertificationCertifiedUsersDialogModel";
import { CertifiedUsersDialog } from "./Tiles/CertifiedUsersDialog";

export function buildDialog(model: IDialogModel): JSX.Element {
  if (model instanceof CertificationCertifiedUsersDialogModel) {
    return <CertifiedUsersDialog model={model} />;
  }

  throw new Error(`Unknown dialog model type: ${typeof model}`);
}
