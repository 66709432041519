import React from "react";
import { observer } from "mobx-react";
import { Button, ListGroup, Modal, Tab, Tabs } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { ItemType } from "../../Models/DashboardItem";

export interface IAddTileDialogProps {
  show: boolean;
  hide: (tileType: string | null) => void;
}

interface ITileCategory {
  key: string;
  title: string;
  tiles: {
    id: ItemType;
    title: string;
    description: string;
  }[];
}

const tileAreas: ITileCategory[] = [
  {
    key: "users",
    title: "Users",
    tiles: [
      {
        id: "user_count",
        title: "Total user count",
        description: "Displays the total number of users",
      },
      {
        id: "activated_user_count",
        title: "Activated user count",
        description:
          "Displays the total number of users which have been activated",
      },
      {
        id: "active_user_count",
        title: "Active user count",
        description: "Displays a count of users who have been active recently",
      },
      {
        id: "registered_user_count",
        title: "User registrations",
        description: "Displays a count of users of recent user registrations",
      },
      {
        id: "login_histogram",
        title: "Login histogram",
        description: "Displays a graph of recent user logins",
      },
    ],
  },
  {
    key: "content",
    title: "Content",
    tiles: [
      {
        id: "content_item_count",
        title: "Content item count",
        description: "Displays the total number of registered content items",
      },
      {
        id: "content_item_types",
        title: "Content item types",
        description:
          "Displays the proportions of types of registered content items",
      },
    ],
  },
  {
    key: "completion",
    title: "Completion",
    tiles: [
      {
        id: "enrolment_histogram",
        title: "Enrolment histogram",
        description: "Displays counts of all content enrolments over time",
      },
      {
        id: "completion_histogram",
        title: "Completion histogram",
        description: "Displays counts of all content completions over time",
      },
      {
        id: "item_enrolment_count",
        title: "Enrolled user count",
        description: "Displays the number of users enrolled on a content item",
      },
      {
        id: "item_attempted_count",
        title: "Launched user count",
        description:
          "Displays the number of users who have launched a content item",
      },
      {
        id: "item_completion_count",
        title: "Completed user count",
        description:
          "Displays the number of users who have completed a content item",
      },
      {
        id: "item_user_statuses",
        title: "User statuses",
        description:
          "Displays the number of users who are enrolled, in progress, or who have completed a content item",
      },
    ],
  },
  {
    key: "certifications",
    title: "Certifications",
    tiles: [
      {
        id: "certification_count",
        title: "Certifications count",
        description: "Displays the total number of certifications configured",
      },
      {
        id: "certification_user_states",
        title: "Certification statuses",
        description: "Displays the statuses of learners for this certification",
      },
      {
        id: "certification_enrolment_count",
        title: "Enrolled users",
        description:
          "Displays the total number of users enrolled on a certification",
      },
      {
        id: "certification_certified_count",
        title: "Certified users",
        description:
          "Displays the total count of users who have been awarded this certification.",
      },
      {
        id: "certification_expired_count",
        title: "Expired users",
        description:
          "Displays the total count of users who have been awarded this certification.",
      },
    ],
  },
];

export const AddTileDialog: React.FC<IAddTileDialogProps> = observer(
  (props: IAddTileDialogProps) => {
    return (
      <Modal show={props.show} onHide={() => props.hide(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Add tile</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ height: 500 }}>
          <Tabs defaultActiveKey={tileAreas[0].key}>
            {tileAreas.map((tileArea) => (
              <Tab
                key={tileArea.key}
                eventKey={tileArea.key}
                title={tileArea.title}
              >
                <ListGroup
                  variant="flush"
                  style={{ paddingTop: 5, height: 430, overflow: "auto" }}
                >
                  {tileArea.tiles.map((t) => (
                    <ListGroup.Item
                      key={t.id}
                      action
                      onClick={() => props.hide(t.id)}
                    >
                      <h5>{t.title}</h5>
                      <p style={{ marginBottom: 0 }}>{t.description}</p>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Tab>
            ))}
          </Tabs>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.hide(null)}>
            <FormattedMessage id="close" defaultMessage="Close" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
);
