import { observer } from "mobx-react";
import React from "react";
import { Form } from "react-bootstrap";
// import { FormattedMessage } from "react-intl";
import { DurationOptionModel } from "../../../Models/Tiles/Options/DurationOptionModel";
import { TimeDurationPicker } from "../../Form/TimeDurationPicker";

export interface IDurationOptionProps {
    model: DurationOptionModel
}

export const DurationOption: React.FC<IDurationOptionProps> =
    observer(({ model }: IDurationOptionProps) => {
        // TODO: Figure out how to nicely localise the label here

        return (
            <Form.Group controlId={model.key}>
                <Form.Label>
                    {model.label}
                </Form.Label>
                <TimeDurationPicker 
                    value={model.selectedDuration}
                    disabled={model.tile.isReadonly}
                    onChange={(v) => model.setSelectedDuration(v)} />
            </Form.Group>
        );
    });