import { makeAutoObservable } from "mobx";
import { IProportionTileModel } from "../../../Controls/Tiles/Graphs/ProportionTile";
import { StatisticRequest } from "../../../Interfaces/stats.interfaces";
import {
  CERTIFICATION_KEY,
  DashboardItem,
  ENROLLED_USERS_KEY,
  ItemDimensions,
  prepareItem,
  TITLE_SUFFIX,
} from "../../DashboardItem";
import { CERTIFICATION_PLACEHOLDER } from "../../Placeholders";
import { DashboardState } from "../../DashboardState";
import { TilePropertiesModel } from "../../Dialogs/TilePropertiesModel";
import { PieDataPoint } from "../ContentItemTypesTileModel";
import {
  BooleanOptionModel,
  FALSE_VALUE,
  TRUE_VALUE,
} from "../Options/BooleanOptionModel";
import { CertificationOptionModel } from "../Options/CertificationOptionModel";
import {
  ProportionGraphOptionModel,
  ProportionGraphType,
  PROPORTION_GRAPH_TYPE_KEY,
  stringFromType,
  typeFromString,
} from "../Options/ProportionGraphOptionModel";

const defaultOptions = {
  [CERTIFICATION_KEY]: "",
  [CERTIFICATION_KEY + TITLE_SUFFIX]: "",
  [ENROLLED_USERS_KEY]: TRUE_VALUE,
};

const certification_status_labels: {
  [status: string]: { label: string };
} = {
  enrolled: {
    label: "Enrolled",
  },
  certified: {
    label: "Certified",
  },
  expired: {
    label: "Expired",
  },
};

export class CertificationUserStatesModel implements IProportionTileModel {
  public propertiesDialogModel: TilePropertiesModel | null = null;

  constructor(
    public readonly dashboard: DashboardState,
    public readonly item: DashboardItem
  ) {
    prepareItem(item, "certification_user_states", defaultOptions);

    makeAutoObservable(this);
  }

  public buildRequests(): StatisticRequest[] {
    if (!this.certificationId) {
      return [];
    }

    return [this.buildRequest()];
  }

  private buildRequest(): StatisticRequest {
    return {
      type: "certification_users_statuses",
      options: {
        [CERTIFICATION_KEY]: this.certificationId || "",
        [ENROLLED_USERS_KEY]:
          this.item.item.options[ENROLLED_USERS_KEY] || FALSE_VALUE,
      },
    };
  }

  public onClickRemove() {
    this.dashboard.removeTile(this);
  }

  public onClickInfo() {
    this.propertiesDialogModel = new TilePropertiesModel(this, [
      new CertificationOptionModel(
        this,
        CERTIFICATION_KEY,
        "Selected certification"
      ),
      new BooleanOptionModel(
        this,
        ENROLLED_USERS_KEY,
        "Only include enrolled users",
        false
      ),
      new ProportionGraphOptionModel(this),
    ]);
  }

  get emptyText() {
    if (!this.certificationId) {
      return "No certification selected";
    }

    return "No enrolled users found";
  }

  get units() {
    return "Learners";
  }

  get defaultLabels() {
    return {
      title: "Learner states",
      subTitle: this.item.item.options[CERTIFICATION_KEY + TITLE_SUFFIX] || "",
    };
  }

  get dimensions(): ItemDimensions {
    return {
      w: 2,
      h: 2,
    };
  }

  public onClickTile() {}

  get isClickable(): boolean {
    return false;
  }

  get canResize(): boolean {
    return false;
  }

  get values(): PieDataPoint[] | null {
    if (!this.certificationId) return [];

    const result = this.dashboard.getStatistic(this.buildRequest());

    if (!result?.response) {
      return null;
    }

    if (result?.response?.type !== "grouped") {
      throw new Error(`Unexpected response type`);
    }

    const { response } = result;

    return response.values.map((value) => {
      const info = certification_status_labels[value.key] || {
        label: "Unknown",
      };

      return {
        title: info.label,
        number: value.value,
      };
    });
  }

  get isLoading(): boolean {
    return this.buildRequests().some(
      (s) => !this.dashboard.getStatistic(s)?.response
    );
  }

  get isReadonly(): boolean {
    return this.dashboard.isReadonly;
  }

  get graphType(): ProportionGraphType {
    const selectedType =
      this.item.item.options[PROPORTION_GRAPH_TYPE_KEY] ||
      stringFromType(ProportionGraphType.Pie);

    return typeFromString(selectedType);
  }

  private get certificationId() {
    const placeholder = this.dashboard.getParameterValue(
      CERTIFICATION_PLACEHOLDER
    );

    if (placeholder) return placeholder;

    return this.item.item.options[CERTIFICATION_KEY] || null;
  }

  private get onlyEnrolledUsers() {
    return this.item.item.options[ENROLLED_USERS_KEY] === TRUE_VALUE;
  }
}
