import { makeAutoObservable } from "mobx";
import { MessageDescriptor } from "react-intl";
import { items } from "../../Controls/Units";
import { StatisticRequest } from "../../Interfaces/stats.interfaces";
import { DashboardItem, ItemDimensions, prepareItem } from "../DashboardItem";
import { DashboardState } from "../DashboardState";
import { IDialogModel } from "../Dialogs/IDialogModel";
import { TilePropertiesModel } from "../Dialogs/TilePropertiesModel";
import { ICountTileModel } from "./ICountTileModel";
import { ContentItemCategoryOptionModel } from "./Options/ContentItemCategoryOptionModel";
import {
  ContentTypeOptionModel,
  CONTENT_TYPE_KEY,
  supportedTypes,
} from "./Options/ContentTypeOptionModel";

const CATEGORY_KEY = "CATEGORY_ID";

const defaultOptions = {
  [CONTENT_TYPE_KEY]: "",
  [CATEGORY_KEY]: "",
};

export class ContentItemCountTileModel implements ICountTileModel {
  public propertiesDialogModel: TilePropertiesModel | null = null;
  public dialog: IDialogModel | null = null;

  constructor(
    public readonly dashboard: DashboardState,
    public readonly item: DashboardItem
  ) {
    prepareItem(item, "content_item_count", defaultOptions);

    makeAutoObservable(this);
  }

  public buildRequests(): StatisticRequest[] {
    return [this.buildRequest()];
  }

  public onClickRemove() {
    this.dashboard.removeTile(this);
  }

  public onClickInfo() {
    this.propertiesDialogModel = new TilePropertiesModel(this, [
      new ContentTypeOptionModel(this),
      new ContentItemCategoryOptionModel(this, "CATEGORY_ID", "Category"),
    ]);
  }

  private buildRequest(): StatisticRequest {
    return {
      type: "content_item_count",
      options: {
        CE_ONLY: "true",
        TYPES: this.selectedType.activityTypes.join(","),
      },
    };
  }

  get selectedType() {
    const type = this.item.item.options[CONTENT_TYPE_KEY] || "*";
    return supportedTypes.find((st) => st.key === type) || supportedTypes[0];
  }

  get defaultLabels() {
    return {
      title: "Content items",
      subTitle: this.selectedType.activityTypes.length
        ? this.selectedType.label
        : "",
    };
  }

  get dimensions(): ItemDimensions {
    return {
      w: 1,
      h: 1,
    };
  }

  get canResize(): boolean {
    return false;
  }

  get value(): number {
    const cs = this.dashboard.getStatistic(this.buildRequest());

    if (!cs || !cs.response) {
      return NaN;
    }

    if (cs?.response?.type !== "value") {
      throw new Error(`Unexpected response type`);
    }

    return cs?.response.value;
  }

  public onClickTile() {}

  get isClickable(): boolean {
    return false;
  }

  get isLoading(): boolean {
    const cs = this.dashboard.getStatistic(this.buildRequest());

    return !cs || !cs.response;
  }

  get isReadonly(): boolean {
    return this.dashboard.isReadonly;
  }

  get hasItem(): boolean {
    return true;
  }

  get emptyText(): string {
    return "";
  }

  get unit(): MessageDescriptor {
    return items;
  }
}
