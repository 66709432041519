import React from "react";

export const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = React.useRef(callback);
  React.useEffect(() => {
    savedCallback.current = callback;
  });
  React.useEffect(() => {
    const tick = () => savedCallback.current();
    if (delay !== null) {
      const current = window.setInterval(tick, delay);
      return () => window.clearInterval(current);
    }
  }, [delay]);
};
