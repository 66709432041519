import { makeAutoObservable } from "mobx";
import { StatisticRequest, ValueResponse } from "../Interfaces/stats.interfaces";

export class CachedStatistic {
    request: StatisticRequest;

    response: ValueResponse | null = null;

    updatedOn: Date;

    constructor(request: StatisticRequest) {
        makeAutoObservable(this);

        this.request = request;
        this.updatedOn = new Date();
    }
}