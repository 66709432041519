import { makeAutoObservable } from "mobx";
import { DashboardState, FilterMode } from "../DashboardState";

export class DashboardFilterDialogModel {
    public filterMode: FilterMode;

    public filterGroupId: string | null;

    constructor(
        private dashboardState: DashboardState
    ) {
        makeAutoObservable(this);

        this.filterMode = dashboardState.filterMode;
        this.filterGroupId = dashboardState.filterByGroupId;
    }

    public setFilterMode(newMode: FilterMode) {
        this.filterMode = newMode;
    }

    public setFilterGroupId(groupId: string | null) {
        this.filterGroupId = groupId;
    }

    public save() {
        this.dashboardState.filterMode = this.filterMode;
        this.dashboardState.filterByGroupId = this.filterGroupId;

        this.close();
    }

    public close() {
        this.dashboardState.filterDialogModel = null;
        this.dashboardState.fetchStats();
    }
}