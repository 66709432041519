import { AuthStore } from "@agylia/nu-web-auth";
import { makeAutoObservable, runInAction } from "mobx";
import _ from "underscore";

import {
  DrillDownRequest,
  StatContext,
  StatisticRequest,
  StatType,
  UsersResponse,
  ValueResponse,
} from "../Interfaces/stats.interfaces";
import { CachedStatistic } from "./CachedStatistic";
import { endpoint } from "./Config";

type OUCompletions = {
  courses: {
    id: string;
    title: string;
  }[];
  ous: {
    name: string;
    results: number[];
  }[];
};

type OU = {
  prefix: string;
  name: string;
  children: OU[];
};

type OUProperty = {
  key: string;
  name: string;
  rootOUs: OU[];
};

export type StatResponse = {
  name: StatType;
  value: ValueResponse;
};

export class StatsStore {
  scope = "dev-dm";

  stats: CachedStatistic[] = [];

  isLoading: boolean = false;
  hadError: boolean = false;
  retryCount: number = 0;
  inFlightRetry: NodeJS.Timeout | null = null;
  updatedOn?: Date;

  ouProperties: OUProperty[] = [];

  ouCompletions: OUCompletions | null = null;

  constructor(private readonly authStore: AuthStore) {
    makeAutoObservable(this);
  }

  getStatistic(request: StatisticRequest): CachedStatistic | undefined {
    let cachedStat = this.stats.find(
      (s) =>
        s.request.type === request.type &&
        _(request.options).isEqual(s.request.options) &&
        this.compareContexts(s.request.context, request.context)
    );

    return cachedStat;
  }

  async fetchStatistic(request: StatisticRequest) {
    let cachedStat = this.getStatistic(request);

    if (!cachedStat) {
      cachedStat = new CachedStatistic(request);

      const stat = cachedStat;

      runInAction(() => {
        this.stats.push(stat);
      });
    }

    await this.doRequest([request]);
  }

  async fetchStatistics(requests: StatisticRequest[]) {
    for (const request of requests) {
      let cachedStat = this.getStatistic(request);

      if (!cachedStat) {
        cachedStat = new CachedStatistic(request);

        const stat = cachedStat;

        runInAction(() => {
          this.stats.push(stat);
        });
      }
    }

    await this.doRequest(requests);
  }

  async doRequest(requests: StatisticRequest[]) {
    const body = {
      stats: requests,
    };

    const token = await this.authStore.getAccessToken();

    const req = fetch(`${endpoint}/stats`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    try {
      const response = await req;

      if (!response.ok) {
        console.error(`Fail status from request: ${response.status}`);

        this.handleFailedRequest(requests);

        return;
      } else {
        this.hadError = false;
        this.retryCount = 0;
      }

      const json = await response.json();

      for (const response of json.stats) {
        const cachedStat = this.getStatistic({
          type: response.type,
          options: response.options,
          context: response.context,
        });

        if (cachedStat) {
          runInAction(() => {
            cachedStat.response = response.value;
            cachedStat.updatedOn = new Date();
          });
        } else {
          console.error(`Unable to find cached stat of type: ${response.type}`);
        }
      }

      runInAction(() => {
        this.updatedOn = new Date();
      });
    } catch (e) {
      console.error(`Error making request: ${e}`);

      this.handleFailedRequest(requests);
    }
  }

  handleFailedRequest(requests: StatisticRequest[]) {
    this.hadError = true;

    this.retryCount++;

    if (this.retryCount < 5) {
      if (this.inFlightRetry === null) {
        this.inFlightRetry = setTimeout(async () => {
          await this.doRequest(requests);

          this.inFlightRetry = null;
        }, 500);
      }
    }
  }

  async drillDown(request: DrillDownRequest): Promise<UsersResponse | null> {
    const token = await this.authStore.getAccessToken();

    const req = fetch(`${endpoint}/stats/drill_down`, {
      method: "POST",
      body: JSON.stringify(request),
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    const response = await req;

    if (!response.ok) {
      this.hadError = true;
      return null;
    } else {
      this.hadError = false;
    }

    const json = await response.json();

    return json;
  }

  async update() {
    await this.doRequest(this.stats.map((s) => s.request));
  }

  private compareContexts(
    a: StatContext | undefined,
    b: StatContext | undefined
  ): boolean {
    if (!a && !b) return true;

    if (!a || !b) return false;

    if (
      a.groupFilters.length !== b.groupFilters.length ||
      a.propertyFilters.length !== b.propertyFilters.length
    )
      return false;

    for (const group of a.groupFilters) {
      if (!b.groupFilters.find((g) => g === group)) return false;
    }

    for (const propertyFilter of a.propertyFilters) {
      if (
        !b.propertyFilters.find(
          (pf) =>
            pf.key === propertyFilter.key &&
            pf.operation === propertyFilter.operation &&
            pf.value === propertyFilter.value
        )
      )
        return false;
    }

    return true;
  }
  /*
    async fetchOUCompletions() {
        this.ouCompletions = null;

        try {
            const response = await fetch(`/scope/${this.scope}/completions/by_ou`);

            const r = await response.json();

            this.ouCompletions = r;

            this.populateOUs(this.ouCompletions!?.ous.map(
                ou => ou.name
            ));
        } catch (error) {
            console.error(error);
        }
    }*/
  /*
    populateOUs(ouNames: string[]) {
        // OUs look like department://Danteborough/Books
        const properties = 
            _(ouNames.map(ou => ou.split(':')))
                .groupBy(l => l[0]);

        for (const property of Object.keys(properties)) {
            const propertyValues = 
                _(properties[property]).toArray();

            const routes = propertyValues.map(p =>
                p[1].split('/').splice(2, 2));

            const groups = _(routes).groupBy(r => r[0]);

            /*const ous = groups.map((key, val) => ({
                name: g[0],
                children: 
            }))
            
            console.log(routes[0]);
        }
    }*/
}
