import { observer } from 'mobx-react';
import React from 'react';
import { Button } from 'react-bootstrap';
import { MdClose } from 'react-icons/md';
import { useStores } from "../../Hooks/use-stores";
import { DashboardState } from "../../Models/DashboardState"

interface IFilterStatusProps {
    dashboardState: DashboardState
}

export const FilterStatus: React.FC<IFilterStatusProps> = 
    observer(({ dashboardState }) => {
    const { usersDataStore } = useStores();

    if (dashboardState.filterMode === 'None') {
        return <></>;
    }
    
     return (
        <Button 
            variant='link'
            style={{ color: 'gray' }}
            onClick={() => dashboardState.updateFilterMode('None')}>
            {dashboardState.filterByGroupId !== null &&
            `Filtered to '${usersDataStore.getGroupName(dashboardState.filterByGroupId)}'`}
            <MdClose />
        </Button>
    );
 });
 