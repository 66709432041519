import { observer } from "mobx-react";
import React from "react";
import { Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { ContentTypeOptionModel } from "../../../Models/Tiles/Options/ContentTypeOptionModel";

interface IContentTypeOptionProps {
    model: ContentTypeOptionModel
}

export const ContentTypeOption: React.FC<IContentTypeOptionProps> =
    observer(({ model }: IContentTypeOptionProps) => {
        return (
            <Form.Group controlId="tile.itemType">
                <Form.Label>
                    <FormattedMessage
                        id="itemTypeLabel"
                        defaultMessage="Item type"
                    />
                </Form.Label>
                <Form.Control 
                    as="select" 
                    disabled={model.tile.isReadonly}
                    value={model.selectedContentType.key}
                    onChange={(e) => model.setSelectedContentType(e.target.value)}>
                        {
                            model.supportedTypes.map(
                                (type) => 
                                <option value={type.key} key={type.key}>{type.label}</option>
                            )
                        }
                </Form.Control>
            </Form.Group>
        )
    });