/* eslint-disable import/first */

// These libs are required for IE11
// import "react-app-polyfill/ie11";
// import "react-app-polyfill/stable";

import "whatwg-fetch";
import "@formatjs/intl-getcanonicallocales/polyfill";
import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/en";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

// We need to do this before we import any of our own code
import { configure } from "mobx";
configure({ useProxies: "never" });

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { IntlProvider } from "react-intl";

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale="en" defaultLocale="en">
      <App />
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
