import { observer } from "mobx-react";
import React from "react";
import { Form } from "react-bootstrap";
import { BooleanOptionModel } from "../../../Models/Tiles/Options/BooleanOptionModel";
interface IBooleanOptionProps {
  model: BooleanOptionModel;
}

export const BooleanOption: React.FC<IBooleanOptionProps> = observer(
  ({ model }: IBooleanOptionProps) => {
    return (
      <Form.Group controlId="tile.boolean">
        <Form.Check
          disabled={model.tile.isReadonly}
          checked={model.selectedValue}
          label={model.label}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            model.setValue(e.currentTarget.checked)
          }
        />
      </Form.Group>
    );
  }
);
