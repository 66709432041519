import { makeAutoObservable } from "mobx";
import { IDialogModel } from "../../Dialogs/IDialogModel";
import { StatsStore } from "../../StatsStore";
import { CertificationCertifiedUserCountModel } from "./CertificationCertifiedUsersModel";

export interface ICertifiedUser {
  id: string;
  name: string;
  thumbnail: string;
  certifiedOn: Date;
  expiresOn: Date;
}

export enum SortColumn {
  Name,
  CertifiedOn,
  ExpiresOn,
}

export enum SortDirection {
  Ascend,
  Descend,
}

export class CertificationCertifiedUsersDialogModel implements IDialogModel {
  public totalUsers: number = 1000;
  public activeSearchText: string = "";
  public visibleSearchText: string = "";
  public sortColumn: SortColumn = SortColumn.CertifiedOn;
  public sortDirection: SortDirection = SortDirection.Ascend;
  public isLoading: boolean = true;

  private usersData: ICertifiedUser[] = [];

  constructor(
    private tile: CertificationCertifiedUserCountModel,
    private statsStore: StatsStore
  ) {
    makeAutoObservable(this);

    this.buildUsers();
  }

  public get users(): ICertifiedUser[] {
    return this.usersData;
  }

  public clickHeading(column: SortColumn) {
    if (this.sortColumn !== column) {
      this.sortColumn = column;
    } else {
      if (this.sortDirection === SortDirection.Ascend) {
        this.sortDirection = SortDirection.Descend;
      } else {
        this.sortDirection = SortDirection.Ascend;
      }
    }
  }

  public save() {}

  public cancel() {
    this.tile.dashboard.dialogStore.hideDialog();
  }

  private async buildUsers() {
    const result = await this.statsStore.drillDown({
      context: this.tile.dashboard.contextFromFilters(),
      options: this.tile.item.item.options,
      type: "certification_certified",
      limit: 50,
      skip: 0,
    });

    if (result === null) {
      return;
    }

    for (const user of result.users) {
      this.usersData.push({
        id: user.id,
        name: user.name,
        thumbnail: user.thumbnail,
        certifiedOn: new Date(user.data["awardedOn"]),
        expiresOn: new Date(user.data["expiresOn"]),
      });
    }

    this.isLoading = false;
  }
}
