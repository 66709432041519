import { makeAutoObservable } from "mobx";
import { certifications } from "../../../Controls/Units";
import { StatisticRequest } from "../../../Interfaces/stats.interfaces";
import {
  DashboardItem,
  ItemDimensions,
  prepareItem,
} from "../../DashboardItem";
import { DashboardState } from "../../DashboardState";
import { IDialogModel } from "../../Dialogs/IDialogModel";
import { TilePropertiesModel } from "../../Dialogs/TilePropertiesModel";
import { ICountTileModel } from "../ICountTileModel";

export class CertificationsCountTileModel implements ICountTileModel {
  public propertiesDialogModel: TilePropertiesModel | null = null;

  constructor(
    public readonly dashboard: DashboardState,
    public readonly item: DashboardItem
  ) {
    prepareItem(item, "certification_count", {});

    makeAutoObservable(this);
  }

  public buildRequests(): StatisticRequest[] {
    return [this.buildRequest()];
  }

  public onClickRemove() {
    this.dashboard.removeTile(this);
  }

  public onClickInfo() {
    this.propertiesDialogModel = new TilePropertiesModel(this, []);
  }

  private buildRequest(): StatisticRequest {
    return {
      type: "content_item_count",
      options: {
        TYPES: "http://cm-luminosity.com/activitytypes/certification",
      },
    };
  }

  get defaultLabels() {
    return {
      title: "Certifications",
      subTitle: "",
    };
  }

  get dimensions(): ItemDimensions {
    return {
      w: 1,
      h: 1,
    };
  }

  get canResize(): boolean {
    return false;
  }

  get value(): number {
    const cs = this.dashboard.getStatistic(this.buildRequest());

    if (!cs || !cs.response) {
      return NaN;
    }

    if (cs?.response?.type !== "value") {
      throw new Error(`Unexpected response type`);
    }

    return cs?.response.value;
  }

  public onClickTile() {}

  public dialog: IDialogModel | null = null;

  get isClickable(): boolean {
    return false;
  }

  get isLoading(): boolean {
    const cs = this.dashboard.getStatistic(this.buildRequest());

    return !cs || !cs.response;
  }

  get isReadonly(): boolean {
    return this.dashboard.isReadonly;
  }

  get hasItem() {
    return true;
  }

  get emptyText() {
    return "";
  }

  get unit() {
    return certifications;
  }
}
