import { observer } from "mobx-react";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { MdAdd, MdLock } from "react-icons/md";
import { Link, Redirect, useLocation } from "react-router-dom";

import { useStores } from "../../Hooks/use-stores";
import { DashboardState } from "../../Models/DashboardState";
import { buildDashboardUrl } from "../../navigationHelpers";
import { NewDashboardPropertiesDialog } from "../Dialogs/NewDashboardPropertiesDialog";

import styles from "./DashboardSelector.module.css";

export interface IDashboardSelectorProps {
  currentDashboard: DashboardState;
}

interface CustomToggleProps {
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef<HTMLAnchorElement, CustomToggleProps>(
  ({ children, onClick }, ref) => (
    // eslint-disable-next-line
    <a
      className={styles.toggleControlTitle}
      href="#"
      role="button"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &nbsp;
      <span className={styles.toggleControlArrow}>&#x25bc;</span>
    </a>
  )
);

export const DashboardSelector: React.FC<IDashboardSelectorProps> = observer(
  ({ currentDashboard }) => {
    const location = useLocation();
    const { dashboardsStore } = useStores();

    if (dashboardsStore.dashboardToLoad) {
      return (
        <Redirect
          to={buildDashboardUrl(dashboardsStore.dashboardToLoad, location)}
        />
      );
    }

    return (
      <Dropdown className={styles.toggleControl}>
        <Dropdown.Toggle as={CustomToggle}>
          {currentDashboard.title}
          {currentDashboard.isReadonly && (
            <MdLock className={styles.selectorLock} />
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {dashboardsStore.dashboards.map((dashboard, i) => {
            const d = dashboard;

            return (
              <Link to={buildDashboardUrl(d, location)}>
                <Dropdown.Item
                  as="div"
                  key={d.id}
                  eventKey={"D" + i}
                  className={styles.dashboardItem}
                >
                  {d.title}
                  {d.isReadonly && <MdLock className={styles.selectorLock} />}
                </Dropdown.Item>
              </Link>
            );
          })}
          <Dropdown.Item
            key="create"
            onClick={() => dashboardsStore.showAddDashboardDialog()}
          >
            <MdAdd />
            Add new dashboard
          </Dropdown.Item>
          <NewDashboardPropertiesDialog
            model={dashboardsStore.addDashboardPropertiesModel}
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  }
);
