import { makeAutoObservable } from "mobx";
import { ITileSpecificOptionModel } from "../../Dialogs/TilePropertiesModel";
import { ITileModel } from "../ITileModel";

export const CONTENT_ITEM_KEY = "CONTENT_ITEM";
export const TITLE_SUFFIX = "_TITLE";

export class ContentItemOptionModel implements ITileSpecificOptionModel {
  public selectedItemId: string | null = null;
  public selectedItemTitle: string | null = null;

  constructor(
    public tile: ITileModel,
    public key: string,
    public label: string
  ) {
    makeAutoObservable(this);

    this.selectedItemId = tile.item.item.options[key] || null;
    this.selectedItemTitle =
      tile.item.item.options[this.key + TITLE_SUFFIX] || null;
  }

  public set(id: string | null, title: string | null) {
    this.selectedItemId = id;
    this.selectedItemTitle = title;
  }

  public save() {
    this.tile.item.item.options[this.key] = this.selectedItemId || "";
    this.tile.item.item.options[this.key + TITLE_SUFFIX] =
      this.selectedItemTitle || "";
  }
}
