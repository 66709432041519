import { makeAutoObservable } from "mobx";
import { ITileSpecificOptionModel } from "../../Dialogs/TilePropertiesModel";
import { CERTIFICATION_PLACEHOLDER } from "../../Placeholders";
import { ITileModel } from "../ITileModel";
import { TITLE_SUFFIX } from "./ContentItemOptionModel";

export class CertificationOptionModel implements ITileSpecificOptionModel {
  public selectedCertificationId: string | null = null;
  public selectedCertificationTitle: string | null = null;

  constructor(
    public tile: ITileModel,
    public key: string,
    public label: string
  ) {
    makeAutoObservable(this);

    // TODO: Having placeholder logic here feels wrong - but maybe this is overcomplicated
    // by supporting multiple certification properties for a given tile. Can we get away with just supporting
    // a single certification and not need all the logic dealing with arbitrary keys?
    const placeholderValue = tile.dashboard.getParameterValue(
      CERTIFICATION_PLACEHOLDER
    );

    this.selectedCertificationId =
      placeholderValue || tile.item.item.options[key] || null;
    this.selectedCertificationTitle =
      tile.item.item.options[this.key + TITLE_SUFFIX] || null;
  }

  public set(id: string | null, title: string | null) {
    this.selectedCertificationId = id;
    this.selectedCertificationTitle = title;
  }

  public save() {
    this.tile.item.item.options[this.key] = this.selectedCertificationId || "";
    this.tile.item.item.options[this.key + TITLE_SUFFIX] =
      this.selectedCertificationTitle || "";
  }

  public get isReadonly() {
    return this.tile.dashboard.isReadonly;
  }
}
