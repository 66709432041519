import { DateTime, Duration } from "luxon";
import { makeAutoObservable } from "mobx";
import { users } from "../../Controls/Units";
import {
  StatisticRequest,
  TimeHistogram,
} from "../../Interfaces/stats.interfaces";
import { formatDuration } from "../../Utilities/DurationFormatter";
import { DashboardItem, ItemDimensions } from "../DashboardItem";
import { DashboardState } from "../DashboardState";
import {
  ITileSpecificOptionModel,
  TilePropertiesModel,
} from "../Dialogs/TilePropertiesModel";
import { IHistogramTileModel } from "./IHistogramTileModel";
import { DurationOptionModel } from "./Options/DurationOptionModel";

const TOTAL_DURATION = "TOTAL_DURATION";
const INTERVAL_DURATION = "INTERVAL_DURATION";

const MAX_INTERVAL_COUNT = 100;

export class UserLoginHistogramTileModel implements IHistogramTileModel {
  public propertiesDialogModel: TilePropertiesModel | null = null;
  public dimensions: ItemDimensions = {
    w: 3,
    h: 2,
  };

  constructor(
    public readonly dashboard: DashboardState,
    public readonly item: DashboardItem
  ) {
    makeAutoObservable(this);

    if (item.item.type !== "login_histogram") {
      throw new Error(
        `UserLoginHistogramTileModel created with unsupported type ${item.item.type}`
      );
    }

    if (item.h !== undefined) this.dimensions.h = item.h;
    if (item.w !== undefined) this.dimensions.w = item.w;
  }

  public buildRequests(): StatisticRequest[] {
    return [this.buildRequest()];
  }

  public onClickRemove() {
    this.dashboard.removeTile(this);
  }

  public onClickInfo() {
    this.propertiesDialogModel = new TilePropertiesModel(
      this,
      [
        new DurationOptionModel(
          this,
          TOTAL_DURATION,
          "Total duration to view histogram over",
          "P3M"
        ),
        new DurationOptionModel(
          this,
          INTERVAL_DURATION,
          "Length of each sub-interval",
          "P1D"
        ),
      ],
      this.validateOptions
    );
  }

  private buildRequest(): StatisticRequest {
    return {
      type: "login_histogram",
      options: {
        end: DateTime.utc().endOf("day").toString(),
        interval: this.interval.toString(),
        start: DateTime.utc().minus(this.duration).startOf("day").toString(),
      },
    };
  }

  defaultLabels = {
    title: "Logins",
    subTitle: `Past ${formatDuration(this.duration)}`,
  };

  get value(): TimeHistogram | null {
    const cs = this.dashboard.getStatistic(this.buildRequest());

    if (!cs || !cs.response) {
      return null;
    }

    if (cs?.response?.type !== "histogram") {
      throw new Error(`Unexpected response type`);
    }

    return cs?.response;
  }

  public onClickTile() {}

  get isClickable(): boolean {
    return false;
  }

  get isLoading(): boolean {
    const cs = this.dashboard.getStatistic(this.buildRequest());

    return !cs || !cs.response;
  }

  get isReadonly(): boolean {
    return this.dashboard.isReadonly;
  }

  get canResize(): boolean {
    return true;
  }

  get hasItem() {
    return true;
  }

  get unit() {
    return users;
  }

  get emptyText() {
    return "";
  }

  get duration() {
    return Duration.fromISO(
      (this.item.item.options && this.item.item.options[TOTAL_DURATION]) ||
        "P3M"
    );
  }

  get interval() {
    return Duration.fromISO(
      (this.item.item.options && this.item.item.options[INTERVAL_DURATION]) ||
        "P1D"
    );
  }

  validateOptions(options: ITileSpecificOptionModel[]): string | null {
    const duration = options[0] as DurationOptionModel;
    const interval = options[1] as DurationOptionModel;

    if (
      duration.duration.as("hours") / interval.duration.as("hours") >
      MAX_INTERVAL_COUNT
    ) {
      return "Too many intervals selected. Please choose a shorter reporting duration or a longer sub-interval";
    }

    return null;
  }
}
