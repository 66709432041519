import { AuthStore } from "@agylia/nu-web-auth";
import { makeAutoObservable, runInAction } from "mobx";
import { endpoint, placeholderValues } from "./Config";
import { DashboardItem } from "./DashboardItem";
import { DashboardState } from "./DashboardState";
import { NewDashboardPropertiesModel } from "./Dialogs/NewDashboardPropertiesModel";
import { DialogStore } from "./DialogStore";
import { StatsStore } from "./StatsStore";
import { ToastStore } from "./ToastStore";

export type PlaceholderType = "CERTIFICATION_ID" | "CATALOGUE_ENTRY_ID";
export interface UserDashboard {
  id: string;
  title: string;
  isReadonly: boolean;
  definition: {
    items: DashboardItem[];
    supportedPlaceholders: {
      type: PlaceholderType;
    }[];
  };
}

export class DashboardsStore {
  dashboards: DashboardState[] = [];

  dashboardToLoad: DashboardState | null = null;

  loading: boolean = false;

  addDashboardPropertiesModel: NewDashboardPropertiesModel | null = null;

  constructor(
    private readonly authStore: AuthStore,
    private readonly statsStore: StatsStore,
    private readonly toastStore: ToastStore,
    private readonly dialogStore: DialogStore
  ) {
    makeAutoObservable(this);
  }

  public showAddDashboardDialog() {
    this.addDashboardPropertiesModel = new NewDashboardPropertiesModel(this);
  }

  public hideAddDashboardDialog() {
    this.addDashboardPropertiesModel = null;
  }

  public addUserDashboard(dashboard: UserDashboard) {
    const newDashboard = new DashboardState(
      this.statsStore,
      this.dialogStore,
      dashboard,
      placeholderValues()
    );

    this.dashboards.push(newDashboard);

    this.selectDashboard(newDashboard);
  }

  public async fetchDashboards(): Promise<void> {
    const route = `${endpoint}/dashboards`;

    if (this.loading) return;

    runInAction(() => {
      this.loading = true;
    });

    const token = await this.authStore.getAccessToken();

    const req = fetch(route, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    const response = await req;

    if (!response.ok) {
      throw new Error(`Error loading dashboards`);
    }

    const json = await response.json();

    runInAction(() => {
      this.dashboards = json.dashboards.map(
        (ud: UserDashboard) =>
          new DashboardState(
            this.statsStore,
            this.dialogStore,
            ud,
            placeholderValues()
          )
      );

      this.loading = false;
    });
  }

  public async saveDashboards(): Promise<void> {
    const route = `${endpoint}/dashboards`;

    const token = await this.authStore.getAccessToken();

    const req = fetch(route, {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        dashboards: this.dashboards
          .filter((d) => !d.isReadonly)
          .map((d) => d.serialize()),
      }),
    });

    const response = await req;

    if (!response.ok) {
      throw new Error(`Error loading dashboards`);
    }

    await response.json();
  }

  public async saveDashboard(dashboard: DashboardState): Promise<void> {
    const route = `${endpoint}/dashboards`;

    if (dashboard.isReadonly) {
      return;
    }

    const token = await this.authStore.getAccessToken();

    const req = fetch(route, {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        dashboards: [dashboard.serialize()],
      }),
    });

    const response = await req;

    if (!response.ok) {
      throw new Error(`Error loading dashboards`);
    }

    await response.json();

    this.toastStore.addToast("Dashboard saved");
  }

  selectDashboard(dashboard: DashboardState) {
    runInAction(() => {
      this.dashboardToLoad = dashboard;
    });
  }
}
