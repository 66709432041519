import { observer } from "mobx-react";
import React from "react";
import { Button } from "react-bootstrap";
import { MdSave } from "react-icons/md";
import { useIntl } from "react-intl";
import { useStores } from "../../Hooks/use-stores";
import { DashboardState } from "../../Models/DashboardState";

export interface ISaveControlProps {
  dashboardState: DashboardState;
}

export const SaveControl: React.FC<ISaveControlProps> = observer(
  ({ dashboardState }) => {
    const intl = useIntl();
    const { dashboardsStore } = useStores();

    if (dashboardState.isReadonly) return <></>;

    return (
      <>
        <Button
          style={{ float: "right" }}
          disabled={dashboardState.isReadonly}
          variant="link"
          className="filter-text"
          title={intl.formatMessage({
            id: "saveDashboard",
            defaultMessage: "Save this dashboard",
          })}
          onClick={(e) => {
            e.preventDefault();
            dashboardsStore.saveDashboard(dashboardState);
          }}
        >
          <MdSave />
        </Button>
      </>
    );
  }
);
