import { observer } from "mobx-react";
import React from "react";
import { Form } from "react-bootstrap";
// import { FormattedMessage } from "react-intl";
import { SingleCatalogueEntryPicker } from "@agylia/nu-web-ui";
import { ContentItemOptionModel } from "../../../Models/Tiles/Options/ContentItemOptionModel";

export interface IContentItemOptionProps {
  model: ContentItemOptionModel;
}

export const ContentItemOption: React.FC<IContentItemOptionProps> = observer(
  ({ model }: IContentItemOptionProps) => {
    // TODO: Figure out how to nicely localise the label here

    return (
      <Form.Group controlId={model.key}>
        <Form.Label>{model.label}</Form.Label>
        <SingleCatalogueEntryPicker
          roles={["ContentAdministrator"]}
          includeDeletedItems={true}
          selectedItemId={model.selectedItemId}
          onChange={(id: string | null, title: string | null) => {
            model.set(id, title);
          }}
        />
      </Form.Group>
    );
  }
);
