import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { autorun } from "mobx";
import { IGraphValue } from "./PieControl";
import { yAxis } from "./Helpers";

export interface IBarGraphControlProps {
  values: IGraphValue[] | null;
  emptyText: string;
  title: string;
  subTitle: string;
  units: string;
  isReadonly: boolean;
  onClickRemove?: () => void;
  onClickInfo?: () => void;
  width: number;
  height: number;
}

export const BarGraphControl: React.FC<IBarGraphControlProps> = ({
  values,
  emptyText,
  title,
  subTitle,
  width,
  height,
  units,
}) => {
  const ref = useRef<SVGSVGElement>(null);
  const margin = { top: 20, right: 25, bottom: 30, left: 25 };

  useEffect(() => {
    if (ref.current) {
      d3.select(ref.current).attr("width", width).attr("height", height);
      //.attr("viewBox", [-width / 2, -height / 2, width, height].join(','));
    }
  }, [height, width]);

  useEffect(() => {
    autorun(() => {
      if (!ref.current || values === null) return;

      // TODO: Instead of clearing on updated, animate
      d3.select(ref.current).selectAll("*").remove();

      const svg = d3.select(ref.current);

      if (values.length === 0) {
        svg.append("text").attr("text-anchor", "middle").text(emptyText);

        return;
      }

      // Map from the index of a bar to its Y position
      const x = d3
        .scaleBand<number>()
        .domain(d3.range(values.length))
        .range([margin.left, width - margin.right])
        .padding(0.1);

      // Map from a value to the length of the bar
      const y = d3
        .scaleLinear<number>()
        .domain([0, d3.max(values, (d) => d.number) || 0])
        .nice()
        .range([height - margin.bottom, margin.top]);

      const xAxis = (g: d3.Selection<SVGGElement, unknown, null, undefined>) =>
        g.attr("transform", `translate(0,${height - margin.bottom})`).call(
          d3
            .axisBottom(x)
            .tickFormat((i) => values[i].title)
            .tickSizeOuter(0)
        );

      svg
        .append("g")
        .selectAll("rect")
        .data(values)
        .join("g")
        .attr("class", "bar")
        .append("rect")
        .attr("x", (d, i: number) => x(i) || "0")
        .attr("height", height - margin.bottom - margin.top)
        .attr("width", x.bandwidth())
        .attr("y", margin.top)
        .attr("class", "bar-background")
        .clone()
        .attr("y", (d) => y(d.number))
        .attr("height", (d) => y(0) - y(d.number))
        .attr("class", "bar-body")
        .append("title")
        .text((d) => `${d.title}: ${d.number.toLocaleString()}`);

      svg.append("g").call(xAxis);

      svg.append("g").call(yAxis(margin.left, width, y, units, false));
    });
  }, [
    ref,
    values,
    emptyText,
    height,
    margin.bottom,
    margin.left,
    margin.right,
    margin.top,
    units,
    width,
  ]);

  return (
    <>
      <h2 className="histogram-title">{title}</h2>

      <svg className="histogram-container" ref={ref}></svg>
      {subTitle && <h3>{subTitle}</h3>}
    </>
  );
};
