import { makeAutoObservable } from "mobx";
import { users } from "../../../Controls/Units";
import { StatisticRequest } from "../../../Interfaces/stats.interfaces";
import {
  CERTIFICATION_KEY,
  DashboardItem,
  ItemDimensions,
  prepareItem,
  TITLE_SUFFIX,
} from "../../DashboardItem";
import { CERTIFICATION_PLACEHOLDER } from "../../Placeholders";
import { DashboardState } from "../../DashboardState";
import { IDialogModel } from "../../Dialogs/IDialogModel";
import { TilePropertiesModel } from "../../Dialogs/TilePropertiesModel";
import { ICountTileModel } from "../ICountTileModel";
import { CertificationOptionModel } from "../Options/CertificationOptionModel";

const defaultOptions = {
  [CERTIFICATION_KEY]: "",
  [CERTIFICATION_KEY + TITLE_SUFFIX]: "",
};

export class CertificationEnrolledUserCountModel implements ICountTileModel {
  public propertiesDialogModel: TilePropertiesModel | null = null;
  public dialog: IDialogModel | null = null;

  constructor(
    public readonly dashboard: DashboardState,
    public readonly item: DashboardItem
  ) {
    prepareItem(item, "certification_enrolment_count", defaultOptions);

    makeAutoObservable(this);
  }

  public buildRequests(): StatisticRequest[] {
    if (this.hasItem) {
      return [this.buildRequest()];
    } else {
      return [];
    }
  }

  public onClickTile() {}

  public onClickRemove() {
    this.dashboard.removeTile(this);
  }

  public onClickInfo() {
    this.propertiesDialogModel = new TilePropertiesModel(this, [
      new CertificationOptionModel(this, CERTIFICATION_KEY, "Certification"),
    ]);
  }

  private buildRequest(): StatisticRequest {
    return {
      type: "certification_enrolments",
      options: {
        [CERTIFICATION_KEY]: this.certificationId || "",
      },
    };
  }

  get dimensions(): ItemDimensions {
    return {
      w: 1,
      h: 1,
    };
  }

  get canResize(): boolean {
    return false;
  }

  public get defaultLabels() {
    return {
      title: "Enrolments",
      subTitle: this.item.item.options[CERTIFICATION_KEY + TITLE_SUFFIX] || "",
    };
  }

  get value(): number {
    const cs = this.dashboard.getStatistic(this.buildRequest());

    if (!cs || !cs.response) {
      return NaN;
    }

    if (cs?.response?.type !== "value") {
      throw new Error(`Unexpected response type`);
    }

    return cs?.response.value;
  }

  get isClickable(): boolean {
    return false;
  }

  get isLoading(): boolean {
    const cs = this.dashboard.getStatistic(this.buildRequest());

    return !cs || !cs.response;
  }

  get isReadonly(): boolean {
    return this.dashboard.isReadonly;
  }

  get hasItem(): boolean {
    return this.certificationId !== null;
  }

  get unit() {
    return users;
  }

  get emptyText() {
    return "No certification selected";
  }

  private get certificationId(): string | null {
    const placeholder = this.dashboard.getParameterValue(
      CERTIFICATION_PLACEHOLDER
    );

    if (placeholder) return placeholder;

    return this.item.item.options[CERTIFICATION_KEY] || null;
  }
}
