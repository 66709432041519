import { makeAutoObservable } from "mobx";
import { ITileSpecificOptionModel } from "../../Dialogs/TilePropertiesModel";
import { ITileModel } from "../ITileModel";

export const TRUE_VALUE = "TRUE";
export const FALSE_VALUE = "FALSE";

export class BooleanOptionModel implements ITileSpecificOptionModel {
  public selectedValue: boolean = false;

  constructor(
    public tile: ITileModel,
    public key: string,
    public label: string,
    public defaultValue: boolean
  ) {
    makeAutoObservable(this);

    this.selectedValue =
      tile.item.item.options[key] === TRUE_VALUE || defaultValue;
  }

  public setValue(value: boolean) {
    this.selectedValue = value;
  }

  public save() {
    this.tile.item.item.options[this.key] = this.selectedValue
      ? TRUE_VALUE
      : FALSE_VALUE;
  }
}
