import React, { useEffect } from "react";

import { observer } from "mobx-react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  useLocation,
} from "react-router-dom";

import { AgyliaContext } from "@agylia/nu-web-ui";

import { useStores } from "./Hooks/use-stores";

import { ToastContainer } from "./Controls/ToastContainer";
import { bgcolor, getToken, nuApiEndpoint } from "./Models/Config";
import { DashboardContextProvider } from "./Contexts/DashboardContext";
import { DashboardById } from "./Dashboard";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { DialogControl } from "./Controls/Dialogs/DialogControl";
import { Helmet } from "react-helmet";
import { buildDashboardUrl } from "./navigationHelpers";

const AppWrapper = () => {
  const token = getToken();

  return (
    <AgyliaContext refreshToken={token || ""} route={nuApiEndpoint}>
      <DashboardContextProvider>
        <App />
      </DashboardContextProvider>
    </AgyliaContext>
  );
};

const InitialDashboardRedirect = observer(() => {
  const { dashboardsStore } = useStores();
  const location = useLocation();

  if (dashboardsStore.dashboards.length > 0) {
    return (
      <Redirect
        to={buildDashboardUrl(dashboardsStore.dashboards[0], location)}
      />
    );
  }

  return <div>Loading...</div>;
});

const App = observer(() => {
  const { dashboardsStore } = useStores();

  useEffect(() => {
    const bgColor = bgcolor();

    if (bgcolor !== null) {
      document.documentElement.style.setProperty("--body-bg", bgColor);
    }
  });

  useEffect(() => {
    dashboardsStore.fetchDashboards();
  }, [dashboardsStore]);

  const loaded = dashboardsStore.dashboards.length > 0;

  if (!loaded) {
    return <div style={{ padding: 20 }}>Loading...</div>;
  }

  return (
    <>
      <Helmet
        titleTemplate="%s - Agylia Dashboards"
        defaultTitle="Agylia Dashboards"
      />
      <ToastContainer />
      <DialogControl />
      <Router>
        <Switch>
          <Route path="/dashboards/:id">
            <DashboardById />
          </Route>
          <Route path="/">
            <InitialDashboardRedirect />
          </Route>
        </Switch>
      </Router>
    </>
  );
});

export default AppWrapper;
