import { makeAutoObservable } from "mobx";
import { IProportionTileModel } from "../../Controls/Tiles/Graphs/ProportionTile";
import { StatisticRequest } from "../../Interfaces/stats.interfaces";
import { DashboardItem, ItemDimensions, prepareItem } from "../DashboardItem";
import { DashboardState } from "../DashboardState";
import { TilePropertiesModel } from "../Dialogs/TilePropertiesModel";
import { CATALOGUE_ENTRY_PLACEHOLDER } from "../Placeholders";
import { PieDataPoint } from "./ContentItemTypesTileModel";
import {
  ContentItemOptionModel,
  CONTENT_ITEM_KEY,
  TITLE_SUFFIX,
} from "./Options/ContentItemOptionModel";
import {
  ProportionGraphOptionModel,
  ProportionGraphType,
  PROPORTION_GRAPH_TYPE_KEY,
  stringFromType,
  typeFromString,
} from "./Options/ProportionGraphOptionModel";

const completion_status_labels: {
  [status: string]: { label: string; color: string };
} = {
  ENROLMENT: {
    label: "Enrolled",
    color: "rgb(66, 136, 181)",
  },
  ATTEMPTED: {
    label: "In progress",
    color: "rgb(232, 246, 164)",
  },
  COMPLETED: {
    label: "Completed",
    color: "rgb(143, 210, 164)",
  },
};

export class ContentItemUserStatusesModel implements IProportionTileModel {
  public propertiesDialogModel: TilePropertiesModel | null = null;

  constructor(
    public readonly dashboard: DashboardState,
    public readonly item: DashboardItem
  ) {
    prepareItem(item, "item_user_statuses", {});

    makeAutoObservable(this);
  }

  public buildRequests(): StatisticRequest[] {
    if (this.hasItem) {
      return [this.buildRequest()];
    } else {
      return [];
    }
  }

  public onClickRemove() {
    this.dashboard.removeTile(this);
  }

  public onClickInfo() {
    this.propertiesDialogModel = new TilePropertiesModel(this, [
      new ProportionGraphOptionModel(this),
      new ContentItemOptionModel(this, CONTENT_ITEM_KEY, "Content item"),
    ]);
  }

  private buildRequest(): StatisticRequest {
    return {
      type: "completion_user_statuses",
      options: {
        [CONTENT_ITEM_KEY]: this.contentItemId || "",
      },
    };
  }

  get dimensions(): ItemDimensions {
    return {
      w: 2,
      h: 2,
    };
  }

  get canResize(): boolean {
    return false;
  }

  public get defaultLabels() {
    return {
      title: "User statuses",
      subTitle: this.item.item.options[CONTENT_ITEM_KEY + TITLE_SUFFIX] || "",
    };
  }

  public onClickTile() {}

  get isClickable(): boolean {
    return false;
  }

  get isLoading(): boolean {
    const cs = this.dashboard.getStatistic(this.buildRequest());

    return !cs || !cs.response;
  }

  get isReadonly(): boolean {
    return this.dashboard.isReadonly;
  }

  get hasItem(): boolean {
    return this.contentItemId !== null;
  }

  private get contentItemId(): string | null {
    const placeholder = this.dashboard.getParameterValue(
      CATALOGUE_ENTRY_PLACEHOLDER
    );

    if (placeholder) return placeholder;

    return this.item.item.options[CONTENT_ITEM_KEY] || null;
  }

  get emptyText() {
    if (!this.hasItem) {
      return "No item selected";
    }

    return "No users found";
  }

  get units() {
    return "Learners";
  }

  get graphType(): ProportionGraphType {
    const selectedType =
      this.item.item.options[PROPORTION_GRAPH_TYPE_KEY] ||
      stringFromType(ProportionGraphType.Pie);

    return typeFromString(selectedType);
  }

  get values(): PieDataPoint[] | null {
    if (!this.hasItem) return [];

    const result = this.dashboard.getStatistic(this.buildRequest());

    if (!result?.response) {
      return null;
    }

    if (result?.response?.type !== "grouped") {
      throw new Error(`Unexpected response type`);
    }

    const { response } = result;

    return response.values.map((value, i) => {
      const info = completion_status_labels[value.key] || {
        label: "Unknown",
        color: "red",
      };

      return {
        title: info.label,
        number: value.value,
        color: info.color,
      };
    });
  }
}
