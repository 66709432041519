import { useEffect, useRef } from "react";
import * as d3 from "d3";

import { TimeHistogramEntry } from "../../../Interfaces/stats.interfaces";
import { yAxis } from "./Helpers";
import { capitalize } from "../../helpers";

export interface ITimeHistogramControl {
  entries: TimeHistogramEntry[];
  units: string;
  width: number;
  height: number;
  margin: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  };
}

export const TimeHistogramControl: React.FC<ITimeHistogramControl> = ({
  entries,
  units,
  width,
  height,
  margin,
}) => {
  const ref = useRef<SVGSVGElement>(null);
  const xAxisPadding = margin.bottom + 20;
  const yAxisPadding = 25;

  useEffect(() => {
    if (!ref.current) return;

    d3.select(ref.current).attr("width", width).attr("height", height);

    if (!entries) return;

    // TODO: Instead of clearing on updated, animate
    d3.select(ref.current).selectAll("*").remove();

    const xExtent = d3.extent(entries, (d) => new Date(d.start));
    const yExtent = d3.extent(entries, (d) => d.count);

    if (
      xExtent[0] === undefined ||
      xExtent[1] === undefined ||
      yExtent[0] === undefined ||
      yExtent[1] === undefined
    ) {
      return;
    }

    // If we don't do this, we get a single horizontal line
    // which doesn't look great
    if (yExtent[1] === 0) yExtent[1] = 1;

    const svg = d3.select(ref.current);

    const x = d3
      .scaleUtc()
      .domain(xExtent)
      .range([margin.left, width - margin.right]);

    const y = d3
      .scaleLinear()
      .domain(yExtent)
      .nice()
      .range([height - xAxisPadding, margin.top]);

    svg
      .append("g")
      .attr("transform", `translate(0,${height - xAxisPadding})`)
      .call(
        d3
          .axisBottom(x)
          .ticks(width / 80)
          .tickSizeOuter(0)
      )
      .call((g) => g.select(".domain").attr("stroke-opacity", 0.1))
      .call((g) =>
        g
          .selectAll(".tick line")
          .clone()
          .attr("y2", -height)
          .attr("stroke-opacity", 0.1)
      );

    svg
      .append("g")
      .call(yAxis(yAxisPadding, width, y, capitalize(units), true));

    let line = d3
      .line<TimeHistogramEntry>()
      .defined((d) => !isNaN(d.count))
      .x((d) => x(new Date(d.start)) || 0)
      .y((d) => y(d.count) || 0);

    svg
      .append("path")
      .datum(entries)
      .attr("fill", "none")
      .attr("class", "histogram-line")
      .attr("stroke-width", 2)
      .attr("stroke-linejoin", "round")
      .attr("stroke-linecap", "round")
      .attr("d", line);
  }, [width, height, entries, ref, units, margin, xAxisPadding]);

  return <svg className="histogram-container" ref={ref}></svg>;
};
