import { observer } from "mobx-react";
import { computeTileDimensions, GRID_ITEM_TITLE_PADDING } from "../../../Grid";
import { PieDataPoint } from "../../../Models/Tiles/ContentItemTypesTileModel";
import { ITileModel } from "../../../Models/Tiles/ITileModel";
import { ProportionGraphType } from "../../../Models/Tiles/Options/ProportionGraphOptionModel";
import { TilePropertiesDialog } from "../../Dialogs/TilePropertiesDialog";
import { IModelProps } from "../../helpers";
import { TileButtons } from "../TileButtons";
import { BarGraphControl } from "./BarGraphControl";
import { PieControl } from "./PieControl";

export interface IProportionTileModel extends ITileModel {
  graphType: ProportionGraphType;
  values: PieDataPoint[] | null;
  emptyText: string;
  units: string;
}

export const ProportionTile: React.FC<IModelProps<
  IProportionTileModel
>> = observer(({ model }) => {
  const tileDimensions = computeTileDimensions(model.dimensions);
  const svgHeight = tileDimensions.height - GRID_ITEM_TITLE_PADDING;

  return (
    <>
      <TileButtons model={model} />
      {model.graphType === ProportionGraphType.Bar && (
        <BarGraphControl
          width={tileDimensions.width}
          height={svgHeight}
          values={model.values}
          title={model.item.title || model.defaultLabels.title}
          subTitle={model.item.subTitle || model.defaultLabels.subTitle}
          emptyText={model.emptyText}
          units={model.units}
          isReadonly={model.isReadonly}
        />
      )}

      {model.graphType === ProportionGraphType.Pie && (
        <PieControl
          width={tileDimensions.width}
          height={svgHeight}
          values={model.values}
          title={model.item.title || model.defaultLabels.title}
          subTitle={model.item.subTitle || model.defaultLabels.subTitle}
          emptyText={model.emptyText}
          isReadonly={model.isReadonly}
        />
      )}
      {model.propertiesDialogModel && (
        <TilePropertiesDialog model={model.propertiesDialogModel} />
      )}
    </>
  );
});
