import { DateTime, Duration } from "luxon";
import { makeAutoObservable } from "mobx";
import { MessageDescriptor } from "react-intl";
import { users } from "../../Controls/Units";

import { StatisticRequest } from "../../Interfaces/stats.interfaces";
import { formatDuration } from "../../Utilities/DurationFormatter";
import { DashboardItem, ItemDimensions, prepareItem } from "../DashboardItem";
import { DashboardState } from "../DashboardState";
import { IDialogModel } from "../Dialogs/IDialogModel";
import { TilePropertiesModel } from "../Dialogs/TilePropertiesModel";
import { ICountTileWithTrendTileModel } from "./ICountWithTrendTileModel";
import { DurationOptionModel } from "./Options/DurationOptionModel";
import {
  TileTrendOptionModel,
  TrendDisplayMode,
  trendFromString,
  TREND_OPTION_KEY,
} from "./Options/TileTrendOptionModel";

const ACTIVE_PERIOD_KEY = "ACTIVE_PERIOD";

const defaultOptions = {
  [ACTIVE_PERIOD_KEY]: "P1M",
  [TREND_OPTION_KEY]: "None",
};

export class ActiveUserCountTileModel implements ICountTileWithTrendTileModel {
  public propertiesDialogModel: TilePropertiesModel | null = null;
  public dialog: IDialogModel | null = null;

  constructor(
    public readonly dashboard: DashboardState,
    public readonly item: DashboardItem
  ) {
    prepareItem(item, "active_user_count", defaultOptions);

    makeAutoObservable(this);
  }

  public buildRequests(): StatisticRequest[] {
    if (this.item.item.type !== "active_user_count") {
      throw new Error(
        `ActiveUserCountTileModel created with unsupported type ${this.item.item.type}`
      );
    }

    const duration = Duration.fromISO(
      (this.item.item.options && this.item.item.options[ACTIVE_PERIOD_KEY]) ||
        "P1M"
    );

    const requests = [
      {
        type: "user_active",
        options: {
          from: DateTime.utc().minus(duration).startOf("day").toString(),
          to: DateTime.utc().endOf("day").toString(),
        },
      },
    ];

    if (this.trendMode !== TrendDisplayMode.None) {
      requests.push({
        type: "user_active",
        options: {
          from: DateTime.utc()
            .minus(duration)
            .minus(duration)
            .startOf("day")
            .toString(),
          to: DateTime.utc().minus(duration).startOf("day").toString(),
        },
      });
    }

    return requests as StatisticRequest[];
  }

  public onClickRemove() {
    this.dashboard.removeTile(this);
  }

  public onClickInfo() {
    this.propertiesDialogModel = new TilePropertiesModel(this, [
      new TileTrendOptionModel(this),
      new DurationOptionModel(this, ACTIVE_PERIOD_KEY, "Active in last", "P1M"),
    ]);
  }

  public onClickTile() {}

  get dimensions(): ItemDimensions {
    return {
      w: 1,
      h: 1,
    };
  }

  get canResize(): boolean {
    return false;
  }

  get defaultLabels() {
    return {
      title: "Active users",
      subTitle: `Past ${formatDuration(this.duration)}`,
    };
  }

  get value(): number {
    const cs = this.dashboard.getStatistic(this.buildRequests()[0]);

    if (!cs || !cs.response) {
      return NaN;
    }

    if (cs?.response?.type !== "value") {
      throw new Error(`Unexpected response type`);
    }

    return cs?.response.value;
  }

  get historicValue(): number {
    const cs = this.dashboard.getStatistic(this.buildRequests()[1]);

    if (!cs || !cs.response) {
      return NaN;
    }

    if (cs?.response?.type !== "value") {
      throw new Error(`Unexpected response type`);
    }

    return cs?.response.value;
  }

  get duration() {
    return Duration.fromISO(this.item.item.options[ACTIVE_PERIOD_KEY]);
  }

  get trendMode(): TrendDisplayMode {
    return trendFromString(this.item.item.options[TREND_OPTION_KEY]);
  }

  get isClickable(): boolean {
    return false;
  }

  get isLoading(): boolean {
    const cs = this.dashboard.getStatistic(this.buildRequests()[0]);

    return !cs || !cs.response;
  }

  get isReadonly(): boolean {
    return this.dashboard.isReadonly;
  }

  get unit(): MessageDescriptor {
    return users;
  }

  get emptyText(): string {
    return "";
  }
  get hasItem(): boolean {
    return true;
  }
}
