import React from "react";
import { DashboardContext } from "../Contexts/DashboardContext";

export const useStores = () => {
  const stores = React.useContext(DashboardContext);

  if (
    !stores.dashboardsStore ||
    !stores.statsStore ||
    !stores.toastStore ||
    !stores.usersDataStore ||
    !stores.dialogStore
  ) {
    throw new Error("Attempt to use dashboards store before initialization");
  }

  return {
    dashboardsStore: stores.dashboardsStore,
    dialogStore: stores.dialogStore,
    statsStore: stores.statsStore,
    toastStore: stores.toastStore,
    usersDataStore: stores.usersDataStore,
  };
};
