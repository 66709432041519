import { observer } from "mobx-react";
import { Button, InputGroup, Modal, Table } from "react-bootstrap";
import {
  MdSearch,
  MdArrowDownward,
  MdArrowUpward,
  MdRefresh,
} from "react-icons/md";
import {
  CertificationCertifiedUsersDialogModel,
  SortColumn,
  SortDirection,
} from "../../../Models/Tiles/Certifications/CertificationCertifiedUsersDialogModel";
import { IModelProps } from "../../helpers";
import { FriendlyDate } from "../../Utility/FriendlyDate";

import styles from "./CertifiedUsersDialog.module.css";

export interface ISortStatusProps {
  direction: SortDirection;
  selectedColumn: SortColumn;
  column: SortColumn;
}

export const SortStatus: React.FC<ISortStatusProps> = observer(
  ({ column, selectedColumn, direction }) => {
    if (column !== selectedColumn) {
      return <></>;
    }

    if (direction === SortDirection.Ascend) {
      return <MdArrowDownward />;
    } else {
      return <MdArrowUpward />;
    }
  }
);

SortStatus.displayName = "SortStatus";

const UsersList: React.FC<IModelProps<
  CertificationCertifiedUsersDialogModel
>> = ({ model }) => {
  return (
    <>
      {model.users.map((u) => (
        <tr key={u.id}>
          <td
            style={{
              width: "40px",
              padding: "0.25rem",
              verticalAlign: "middle",
            }}
          >
            <img alt={u.name} src={u.thumbnail} style={{ width: 30 }} />
          </td>
          <td style={{ verticalAlign: "middle" }}>{u.name}</td>
          <td style={{ verticalAlign: "middle" }}>
            <FriendlyDate date={u.certifiedOn} />
          </td>
          <td style={{ verticalAlign: "middle" }}>
            <FriendlyDate date={u.expiresOn} />
          </td>
        </tr>
      ))}
    </>
  );
};

UsersList.displayName = "UsersList";

const LoadingTable = () => {
  return (
    <>
      <MdRefresh />
    </>
  );
};

export const CertifiedUsersDialog: React.FC<IModelProps<
  CertificationCertifiedUsersDialogModel
>> = observer(({ model }) => {
  return (
    <Modal show={true} onHide={() => model.cancel()} size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title>Certified users</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup>
          <input
            className="form-control search"
            type="text"
            placeholder="Search"
            value={model.visibleSearchText}
            onChange={(e) => {
              model.visibleSearchText = e.currentTarget.value;
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
              }
            }}
          />
          <InputGroup.Append>
            <Button className="search-button" onClick={() => {}}>
              <MdSearch />
            </Button>
          </InputGroup.Append>
        </InputGroup>

        <Table>
          <thead>
            <tr>
              <th
                className={styles.sortableHeading}
                colSpan={2}
                onClick={() => model.clickHeading(SortColumn.Name)}
              >
                Name
                <SortStatus
                  column={SortColumn.Name}
                  selectedColumn={model.sortColumn}
                  direction={model.sortDirection}
                />
              </th>
              <th
                className={styles.sortableHeading}
                onClick={() => model.clickHeading(SortColumn.CertifiedOn)}
              >
                Certified on
                <SortStatus
                  column={SortColumn.CertifiedOn}
                  selectedColumn={model.sortColumn}
                  direction={model.sortDirection}
                />
              </th>
              <th
                className={styles.sortableHeading}
                onClick={() => model.clickHeading(SortColumn.ExpiresOn)}
              >
                Expires on{" "}
                <SortStatus
                  column={SortColumn.ExpiresOn}
                  selectedColumn={model.sortColumn}
                  direction={model.sortDirection}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {model.isLoading ? <LoadingTable /> : <UsersList model={model} />}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ marginRight: "auto" }}>
          Showing {model.users.length} of {model.totalUsers} users
        </div>
        <Button onClick={() => model.cancel()}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
});

CertifiedUsersDialog.displayName = "CertifiedUsersDialog";
