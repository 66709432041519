import { makeAutoObservable } from "mobx";
import { users } from "../../Controls/Units";
import { StatisticRequest } from "../../Interfaces/stats.interfaces";
import { DashboardItem, ItemDimensions, prepareItem } from "../DashboardItem";
import { DashboardState } from "../DashboardState";
import { IDialogModel } from "../Dialogs/IDialogModel";
import { TilePropertiesModel } from "../Dialogs/TilePropertiesModel";
import { CATALOGUE_ENTRY_PLACEHOLDER } from "../Placeholders";
import { ICountTileModel } from "./ICountTileModel";
import {
  ContentItemOptionModel,
  CONTENT_ITEM_KEY,
  TITLE_SUFFIX,
} from "./Options/ContentItemOptionModel";

const defaultOptions = {
  [CONTENT_ITEM_KEY]: "",
  [CONTENT_ITEM_KEY + TITLE_SUFFIX]: "",
};

export class ContentItemAttemptedModel implements ICountTileModel {
  public propertiesDialogModel: TilePropertiesModel | null = null;
  public dialog: IDialogModel | null = null;

  constructor(
    public readonly dashboard: DashboardState,
    public readonly item: DashboardItem
  ) {
    prepareItem(item, "item_attempted_count", defaultOptions);

    makeAutoObservable(this);
  }

  public buildRequests(): StatisticRequest[] {
    if (this.hasItem) {
      return [this.buildRequest()];
    } else {
      return [];
    }
  }

  public onClickRemove() {
    this.dashboard.removeTile(this);
  }

  public onClickInfo() {
    this.propertiesDialogModel = new TilePropertiesModel(this, [
      new ContentItemOptionModel(this, CONTENT_ITEM_KEY, "Content item"),
    ]);
  }

  private buildRequest(): StatisticRequest {
    return {
      type: "completion_attempted_count",
      options: {
        [CONTENT_ITEM_KEY]: this.contentItemId || "",
      },
    };
  }

  get dimensions(): ItemDimensions {
    return {
      w: 1,
      h: 1,
    };
  }

  get canResize(): boolean {
    return false;
  }

  public get defaultLabels() {
    return {
      title: "Launched",
      subTitle: this.item.item.options[CONTENT_ITEM_KEY + TITLE_SUFFIX] || "",
    };
  }

  get value(): number {
    const cs = this.dashboard.getStatistic(this.buildRequest());

    if (!cs || !cs.response) {
      return NaN;
    }

    if (cs?.response?.type !== "value") {
      throw new Error(`Unexpected response type`);
    }

    return cs?.response.value;
  }

  public onClickTile() {}

  get isClickable(): boolean {
    return false;
  }

  get isLoading(): boolean {
    const cs = this.dashboard.getStatistic(this.buildRequest());

    return !cs || !cs.response;
  }

  get isReadonly(): boolean {
    return this.dashboard.isReadonly;
  }

  get hasItem(): boolean {
    return this.contentItemId !== null;
  }

  get emptyText() {
    return "No item selected";
  }

  get unit() {
    return users;
  }

  private get contentItemId(): string | null {
    const placeholder = this.dashboard.getParameterValue(
      CATALOGUE_ENTRY_PLACEHOLDER
    );

    if (placeholder) return placeholder;

    return this.item.item.options[CONTENT_ITEM_KEY] || null;
  }
}
