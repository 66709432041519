import { StatContext } from "../Interfaces/stats.interfaces";

export interface UserTrafficLightsItem {
  type: "user_traffic_lights";
}

export interface CompliancyItem {
  type: "compliancy";
}

export interface ResultsMatrixItem {
  type: "results_matrix";
}

export type ItemType =
  | "user_count"
  | "activated_user_count"
  | "active_user_count"
  | "registered_user_count"
  | "login_histogram"
  | "content_item_count"
  | "content_item_types"
  | "item_enrolment_count"
  | "item_attempted_count"
  | "item_completion_count"
  | "item_completion_histogram"
  | "item_user_statuses"
  | "completion_histogram"
  | "enrolment_histogram"
  | "certification_count"
  | "certification_enrolment_count"
  | "certification_certified_count"
  | "certification_expired_count"
  | "certification_user_states";

// These keys are used in the options of the ItemData
export const CERTIFICATION_KEY = "CERTIFICATION";
export const ENROLLED_USERS_KEY = "ONLY_ENROLLED";

export const TITLE_SUFFIX = "_TITLE";

export type ItemOptions = { [key: string]: string };

export interface ItemData {
  type: ItemType;
  options: ItemOptions;
}

// Details around where an item is on the grid, its unique ID, and how large it is
export type GridLayout = {
  x: number;
  y: number;
  w: number;
  h: number;
  minW: number;
  minH: number;
};

export type ItemDimensions = {
  w: number;
  h: number;
};

export type Item = {
  i: string;
  type: ItemType;
  item: ItemData;
  title?: string;
  subTitle?: string;
};

export type DashboardItem = {
  id: string;
  x: number;
  y: number;
  w?: number;
  h?: number;
  title?: string;
  subTitle?: string;
  item: ItemData;
  context?: StatContext;
  gridLayout?: GridLayout;
};

export const prepareItem = (
  item: DashboardItem,
  type: ItemType,
  defaultOptions: ItemOptions
) => {
  if (item.item.type !== type) {
    throw new Error(`${type} created with unsupported type ${item.item.type}`);
  }

  item.item.options = Object.assign({}, defaultOptions, item.item.options);
};
