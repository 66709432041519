import { makeAutoObservable } from "mobx";
import { MessageDescriptor } from "react-intl";
import { users } from "../../Controls/Units";
import { StatisticRequest } from "../../Interfaces/stats.interfaces";
import { DashboardItem, ItemDimensions, prepareItem } from "../DashboardItem";
import { DashboardState } from "../DashboardState";
import { IDialogModel } from "../Dialogs/IDialogModel";
import { TilePropertiesModel } from "../Dialogs/TilePropertiesModel";
import { ICountTileModel } from "./ICountTileModel";

export class ActivatedUserCountTileModel implements ICountTileModel {
  public propertiesDialogModel: TilePropertiesModel | null = null;
  public dialog: IDialogModel | null = null;

  public defaultLabels = {
    title: "Activated users",
    subTitle: "",
  };

  constructor(
    public readonly dashboard: DashboardState,
    public readonly item: DashboardItem
  ) {
    prepareItem(item, "activated_user_count", {});

    makeAutoObservable(this);
  }

  public buildRequests(): StatisticRequest[] {
    return [this.buildRequest()];
  }

  public onClickRemove() {
    this.dashboard.removeTile(this);
  }

  public onClickInfo() {
    this.propertiesDialogModel = new TilePropertiesModel(this, []);
  }

  private buildRequest(): StatisticRequest {
    return {
      type: "user_activated_count",
      options: {},
    };
  }

  get dimensions(): ItemDimensions {
    return {
      w: 1,
      h: 1,
    };
  }

  get canResize(): boolean {
    return false;
  }

  get value(): number {
    const cs = this.dashboard.getStatistic(this.buildRequest());

    if (!cs || !cs.response) {
      return NaN;
    }

    if (cs?.response?.type !== "value") {
      throw new Error(`Unexpected response type`);
    }

    return cs?.response.value;
  }

  public onClickTile() {}

  get isClickable(): boolean {
    return false;
  }

  get isLoading(): boolean {
    const cs = this.dashboard.getStatistic(this.buildRequest());

    return !cs || !cs.response;
  }

  get isReadonly(): boolean {
    return this.dashboard.isReadonly;
  }

  get hasItem(): boolean {
    return true;
  }

  get unit(): MessageDescriptor {
    return users;
  }

  get emptyText(): string {
    return "";
  }
}
