import { observer } from "mobx-react";
import React from "react";
import { MdClose, MdInfoOutline } from "react-icons/md";
import { AiOutlineDrag } from "react-icons/ai";

import { ITileModel } from "../../Models/Tiles/ITileModel";

import styles from "./TileButtons.module.css";

interface ITileButtonsProps {
  model: ITileModel;
}

export const TileButtons: React.FC<ITileButtonsProps> = observer(
  ({ model }) => {
    return (
      <>
        {!model.isReadonly && (
          <MdClose
            className={styles.tileRemoveButton}
            onClick={(e) => {
              e.preventDefault();
              model.onClickRemove();
            }}
          />
        )}

        {model.onClickInfo && (
          <MdInfoOutline
            className={styles.tileInfoButton}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              model.onClickInfo();
            }}
          />
        )}
        {!model.isReadonly && <AiOutlineDrag className="grid-item-handle" />}
      </>
    );
  }
);

TileButtons.displayName = "TileButtons";
