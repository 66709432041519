import { observer } from "mobx-react";
import React from "react";
import { Form } from "react-bootstrap";
// import { FormattedMessage } from "react-intl";
import { SingleCertificationPicker } from "@agylia/nu-web-ui";
import { CertificationOptionModel } from "../../../Models/Tiles/Options/CertificationOptionModel";

export interface ICertificationOptionProps {
  model: CertificationOptionModel;
}

export const CertificationOption: React.FC<ICertificationOptionProps> = observer(
  ({ model }: ICertificationOptionProps) => {
    // TODO: Figure out how to nicely localise the label here

    return (
      <Form.Group controlId={model.key}>
        <Form.Label>{model.label}</Form.Label>
        <SingleCertificationPicker
          isReadonly={model.isReadonly}
          selectedCertificationId={model.selectedCertificationId}
          onChange={(id: string | null, title: string | null) => {
            model.set(id, title);
          }}
        />
      </Form.Group>
    );
  }
);

CertificationOption.displayName = "CertificationOption";
