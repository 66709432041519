import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { autorun } from "mobx";
import { PieArcDatum } from "d3";
import { PieDataPoint } from "../../../Models/Tiles/ContentItemTypesTileModel";

// TODO: Where should this live?
export interface IGraphValue {
  number: number;
  title: string;
  color?: string;
}

export interface IPieControlProps {
  values: IGraphValue[] | null;
  emptyText: string;
  title: string;
  subTitle: string;
  isReadonly: boolean;
  onClickRemove?: () => void;
  onClickInfo?: () => void;
  width: number;
  height: number;
}

export const PieControl: React.FC<IPieControlProps> = ({
  values,
  emptyText,
  title,
  subTitle,
  width,
  height,
}) => {
  const ref = useRef<SVGSVGElement>(null);

  const pieRadius = Math.min(width, height) / 2 - 1;
  const arc = d3
    .arc<void, PieArcDatum<PieDataPoint>>()
    .innerRadius(0)
    .outerRadius(pieRadius);

  useEffect(() => {
    if (ref.current) {
      d3.select(ref.current)
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [-width / 2, -height / 2, width, height].join(","));
    }
  }, [height, width]);

  useEffect(() => {
    autorun(() => {
      if (!ref.current || values === null) return;

      // TODO: Instead of clearing on updated, animate
      d3.select(ref.current).selectAll("*").remove();

      const svg = d3.select(ref.current);

      if (
        values.length === 0 ||
        values.reduce((t, v) => t + v.number, 0) === 0
      ) {
        svg.append("text").attr("text-anchor", "middle").text(emptyText);

        return;
      }

      const labelRadius =
        (Math.min(width, height) / 2) *
        (values.length === 1 ? 0 : values.length < 4 ? 0.5 : 0.7);
      const arcLabel = d3
        .arc<void, PieArcDatum<IGraphValue>>()
        .innerRadius(labelRadius)
        .outerRadius(labelRadius);

      const pie = d3
        .pie<IGraphValue>()
        .sort(null)
        .value((d) => d.number);

      const arcs = pie(values);

      svg
        .append("g")
        .attr("stroke", "white")
        .selectAll("path")
        .data(arcs)
        .join("path")
        .attr("class", "pie-segment")
        .attr("d", arc)
        .append("title")
        .text((d) => `${d.data.title}: ${d.data.number.toLocaleString()}`);

      svg
        .append("g")
        .attr("class", "label-text")
        .attr("font-family", "Arial, sans-serif")
        .attr("font-size", 12)
        .attr("text-anchor", "middle")
        .selectAll("text")
        .data(arcs)
        .join("text")
        .attr("transform", (d) => `translate(${arcLabel.centroid(d)})`)
        .call((text) =>
          text
            .append("tspan")
            .attr("y", "0.2em")
            .attr("text-anchor", "middle")
            .attr("font-weight", "bold")
            .text((d) => (d.data.number > 0 ? d.data.title : ""))
        );
    });
  }, [ref, values, arc, emptyText, height, width]);

  return (
    <>
      <h2 className="histogram-title">{title}</h2>

      <svg className="histogram-container" ref={ref}></svg>
      {subTitle && <h3>{subTitle}</h3>}
    </>
  );
};
