import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useStores } from '../../Hooks/use-stores';
import { DashboardFilterDialogModel } from '../../Models/Dialogs/DashboardFilterDialogModel';
import { UserGroupPickerDialog } from './SingleGroupPickerDialog';

export interface IDashboardFilterDialogProps {
    model: DashboardFilterDialogModel
}

export const DashboardFilterDialog: React.FC<IDashboardFilterDialogProps> = 
    observer((props: IDashboardFilterDialogProps) => {
        const { model } = props;
        const { usersDataStore } = useStores();

        const [ showGroupDialog, setShowGroupDialog ] = useState(false);

    return (
        <Modal 
            show={true}
            onEnter={() => usersDataStore.fetchGroups()}
            onHide={() => model.close()}>
            <Modal.Header closeButton>
                <Modal.Title>
                <FormattedMessage
                    id="filterThisDashboard"
                    defaultMessage="Filter this dashboard"
                    />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Check
                            id="doNotFilter"
                            type="radio"
                            label="Do not filter this dashboard"
                            checked={model.filterMode === 'None'}
                            onChange={() => model.setFilterMode('None')}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Row style={{ alignItems: 'center'}}>
                            <Col xs="auto">
                                <Form.Check
                                    id="filterByGroup"
                                    type="radio"
                                    label="Filter by group"
                                    checked={model.filterMode === 'Group'}
                                    onChange={() => model.setFilterMode('Group')}
                                />
                            </Col>
                            <Col xs="auto">
                                <Button 
                                    disabled={model.filterMode !== 'Group'}
                                    onClick={() => setShowGroupDialog(true)}>Select group</Button>
                            </Col>
                        </Form.Row>
                        <UserGroupPickerDialog 
                            show={showGroupDialog}
                            hide={(selectedGroupId) => {
                                model.setFilterGroupId(selectedGroupId);

                                setShowGroupDialog(false);
                            }}
                            selectedGroupId={model.filterGroupId}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer>
            <Button variant="secondary" onClick={() => model.save()}>
                <FormattedMessage
                    id="close"
                    defaultMessage="Close"
                    />
            </Button>
            </Modal.Footer>
        </Modal>
    );
})