import { observer } from "mobx-react";
import { useStores } from "../../Hooks/use-stores";
import { buildDialog } from "./buildDialog";

export const DialogControl = observer(() => {
  const { dialogStore } = useStores();

  return dialogStore.currentDialog ? (
    buildDialog(dialogStore.currentDialog)
  ) : (
    <></>
  );
});
