import React from "react";
import { observer } from "mobx-react";
import { useIntl } from "react-intl";
import { IModelProps } from "../helpers";
import { TilePropertiesDialog } from "../Dialogs/TilePropertiesDialog";
import { CountControl } from "./CountControl";
import { TileButtons } from "./TileButtons";
import { ICountTileWithTrendTileModel } from "../../Models/Tiles/ICountWithTrendTileModel";

export const CountTileWithTrend: React.FC<IModelProps<
  ICountTileWithTrendTileModel
>> = observer(({ model }) => {
  const intl = useIntl();

  return (
    <>
      <TileButtons model={model} />

      <CountControl
        title={model.item.title || model.defaultLabels.title}
        subTitle={model.item.subTitle || model.defaultLabels.subTitle}
        trendDisplayMode={model.trendMode}
        historicCount={model.historicValue}
        count={model.value}
        unit={intl.formatMessage(model.unit, { count: model.value }) as string}
        loading={model.isLoading}
        message={!model.hasItem ? model.emptyText : undefined}
      />

      {model.propertiesDialogModel && (
        <TilePropertiesDialog model={model.propertiesDialogModel} />
      )}
    </>
  );
});

CountTileWithTrend.displayName = "CountTileWithTrend";
