import React from "react";
import { observer } from "mobx-react";
import { useIntl } from "react-intl";
import { IModelProps } from "../helpers";
import { TrendDisplayMode } from "../../Models/Tiles/Options/TileTrendOptionModel";
import { TilePropertiesDialog } from "../Dialogs/TilePropertiesDialog";
import { CountControl } from "./CountControl";
import { TileButtons } from "./TileButtons";
import { ICountTileModel } from "../../Models/Tiles/ICountTileModel";

export const CountTile: React.FC<IModelProps<ICountTileModel>> = observer(
  ({ model }) => {
    const intl = useIntl();

    return (
      <>
        <TileButtons model={model} />

        <CountControl
          title={model.item.title || model.defaultLabels.title}
          subTitle={model.item.subTitle || model.defaultLabels.subTitle}
          trendDisplayMode={TrendDisplayMode.None}
          count={model.value}
          unit={
            intl.formatMessage(model.unit, { count: model.value }) as string
          }
          loading={model.isLoading}
          message={!model.hasItem ? model.emptyText : undefined}
        />

        {model.propertiesDialogModel && (
          <TilePropertiesDialog model={model.propertiesDialogModel} />
        )}
      </>
    );
  }
);

CountTile.displayName = "CountTile";
