import { makeAutoObservable } from "mobx";
import { Duration } from "luxon";
import { ITileSpecificOptionModel } from "../../Dialogs/TilePropertiesModel";
import { ITileModel } from "../ITileModel";

export class DurationOptionModel implements ITileSpecificOptionModel {
  public selectedDuration: string = "D1M";

  constructor(
    public tile: ITileModel,
    public key: string,
    public label: string,
    public defaultValue: string
  ) {
    makeAutoObservable(this);

    this.selectedDuration = tile.item.item.options[key] || defaultValue;
  }

  public setSelectedDuration(duration: string) {
    this.selectedDuration = duration;
  }

  public get duration() {
    return Duration.fromISO(this.selectedDuration);
  }

  public save() {
    this.tile.item.item.options[this.key] = this.selectedDuration;
  }
}
