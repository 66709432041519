import { observer } from "mobx-react";
import { useStores } from "../Hooks/use-stores";
import { Toast } from "react-bootstrap";

import styles from "./ToastContainer.module.css";

export const ToastContainer: React.FC<{}> = observer(() => {
  const { toastStore } = useStores();

  return (
    <div className={styles.container}>
      {toastStore.toasts.map((t) => (
        <Toast key={t.id} onClose={() => toastStore.removeToast(t)} autohide>
          <Toast.Header>{t.title}</Toast.Header>
        </Toast>
      ))}
    </div>
  );
});

ToastContainer.displayName = "ToastContainer";
